import { useEffect } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import CloseIcon from '../../icons/close-icon';
import Body1 from '../../typography/body-1';
import Body2 from '../../typography/body-2';

import Typography from '../../typography.module.css';
import styles from './global-banner.module.css';

function GlobalBanner({ banner, classes, showCloseButton, onClose, onError }) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (banner.type === 'error') {
      onError(banner);
    }
  }, [banner, onError]);

  return (
    <div
      className={classNames(
        styles.bannerContainer,
        styles[banner.type],
        classes.root
      )}
      data-testid='global-banner-wrapper'
    >
      {banner.icon ? (
        <img
          alt=''
          className={classNames(styles.bannerIcon, classes.bannerIcon)}
          src={banner.icon}
        />
      ) : null}

      <div>
        <div className={styles.bannerTitle} data-testid='global-banner-title'>
          <Body2>
            <span className={styles[banner.type]}>{banner.title}</span>
          </Body2>
        </div>

        <Body1 data-testid='global-banner-message'>
          <span className={styles[banner.type]}>{banner.message}</span>
        </Body1>
      </div>

      {showCloseButton ? (
        <button
          role='button'
          data-testid='global-banner-close-button'
          onClick={onClose}
        >
          <CloseIcon
            className={classNames(
              Typography.h2,
              styles.closeButton,
              classes.closeButton
            )}
          />
        </button>
      ) : null}
    </div>
  );
}

export default GlobalBanner;

GlobalBanner.defaultProps = {
  classes: {},
  showCloseButton: true,
  onClose: () => {},
  onError: () => {},
};

GlobalBanner.propTypes = {
  banner: PropTypes.shape({
    type: PropTypes.oneOf(['error', 'info', 'warning']).isRequired,
    icon: PropTypes.string,
    title: PropTypes.node,
    message: PropTypes.node,
    code: PropTypes.node,
  }).isRequired,

  classes: PropTypes.shape({
    root: PropTypes.string,
    bannerIcon: PropTypes.string,
    closeButton: PropTypes.string,
  }),

  showCloseButton: PropTypes.bool,
  onClose: PropTypes.func,
  onError: PropTypes.func,
};
