import Store from 'store';

export function assessIsGuidedAndFirstVisit(guideKey) {
  return !!guideKey && !Store.get('tooltipGuided')?.[guideKey];
}

export function setWasGuided(guideKey) {
  Store.set('tooltipGuided', {
    ...Store.get('tooltipGuided'),
    [guideKey]: true,
  });
}

export function clearGuide() {
  Store.remove('tooltipGuided');
}
