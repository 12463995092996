import { useState } from 'react';
import PropTypes from 'prop-types';

import TextField from '../text-field';

function TextFieldWithValidation(props) {
  const { validation, onBlur, helperText, ...rest } = props;
  const [errorMessage, updateErrorMessage] = useState('');

  function validate(e) {
    const result = validation(e.target.value);
    if (result === true) {
      updateErrorMessage('');
      onBlur(e);
    } else {
      updateErrorMessage(result === false ? helperText : result);
    }
  }

  return <TextField {...rest} error={{ errorMessage }} onBlur={validate} />;
}

TextFieldWithValidation.defaultProps = {
  helperText: 'Invalid field',
  onBlur: () => {},
};

TextFieldWithValidation.propTypes = {
  /** Determine when your field is in an error state or not */
  validation: PropTypes.func.isRequired,
  /** Display a specific error message if field is not compliant */
  helperText: PropTypes.string,

  // TextField PropTypes
  classes: PropTypes.shape({
    label: PropTypes.string,
    input: PropTypes.string,
    container: PropTypes.string,
    fieldContainer: PropTypes.string,
  }),
  label: PropTypes.string,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  isLabelHidden: PropTypes.bool,
  variant: PropTypes.oneOf(['default', 'outline']),
  propRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  inputRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  endAdornment: PropTypes.shape({
    icon: PropTypes.node,
    isLoading: PropTypes.bool,
    endContent: PropTypes.node,
    endCustom: PropTypes.node,
    onIconClick: PropTypes.func,
  }),
  startAdornment: PropTypes.shape({
    startContent: PropTypes.node,
    startCustom: PropTypes.node,
  }),

  // spread via ...rest
  autoComplete: PropTypes.oneOf(['off', 'on']),
  autoFocus: PropTypes.bool,
  maxLength: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  onKeyUp: PropTypes.func,
  type: PropTypes.string,
  name: PropTypes.string,
  'data-testid': PropTypes.string,
  id: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  defaultValue: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  readOnly: PropTypes.bool,
};

export default TextFieldWithValidation;
