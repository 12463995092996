export const NONE_MEDICATION = 'None of the list';

export const DOG_PROHEART = 'Proheart';

export const DOG_MEDICATIONS = [
  'Simparica Trio',
  'Simparica',
  'Revolution',
  'Nexgard',
  'Bravecto',
  'Effitix',
  'Advantage',
  'Seresto',
  'Frontline/Gold (combined)',
  'Comfortis',
  'Vectra 3D',
  'Advantix',
  'Credelio',
  'Capstar',
  'Parastar',
  'Activyl',
];

export const DOG_MEDICATIONS_WITH_PROHEART = [...DOG_MEDICATIONS, DOG_PROHEART];

export const SIMPARICA_TRIO_OPTION = 'TRIO';
export const SIMPARICA_PROHEART_OPTION = 'PROHEART';

export const SIMPARICA_TRIO_PROTECTION_MEDS = ['Simparica Trio'];
export const SIMPARICA_PROHEART_PROTECTION_MEDS = ['Simparica', DOG_PROHEART];

export const CAT_MEDICATIONS = [
  'Revolution',
  'Revolution Plus',
  'Bravecto Topical',
  'Advantage Multi',
  'Seresto',
  'Frontline Gold',
  'Cheristin',
  'Advantage',
  'Capstar',
  'Comfortis',
  'Frontline',
  'Heartgard',
  'Catego',
  'Vectra',
  'Activyl',
];
