import classNames from 'classnames';
import PropTypes from 'prop-types';

import Typography from '../../typography.module.css';

function LegalBody({ children, className = '', isBold = false, ...rest }) {
  return (
    <div
      className={classNames(
        Typography.legalBody,
        isBold ? Typography.bold : null,
        className
      )}
      {...rest}
    >
      {children}
    </div>
  );
}

LegalBody.propTypes = {
  'aria-level': PropTypes.string,
  className: PropTypes.string,
  'data-testid': PropTypes.string,
  id: PropTypes.string,
  itemProp: PropTypes.string,
  onClick: PropTypes.func,
  role: PropTypes.string,
  style: PropTypes.object,
  isBold: PropTypes.bool,
};

export default LegalBody;
