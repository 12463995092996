import SvgIcon from './svg-icon';

function KeyboardArrowDown(props) {
  return (
    <SvgIcon {...props}>
      <path d='M7.41 8.59 12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z' />
    </SvgIcon>
  );
}

export default KeyboardArrowDown;
