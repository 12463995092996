// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".radio-group_root_377c25eb {\n  display: flex;\n  flex-wrap: nowrap;\n}\n\n.radio-group_row_d4d91e5e {\n  flex-direction: row;\n}\n", "",{"version":3,"sources":["webpack://../../libs/shared/src/ui/inputs/radio-group/radio-group.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,iBAAiB;AACnB;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":[".root {\n  display: flex;\n  flex-wrap: nowrap;\n}\n\n.row {\n  flex-direction: row;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "radio-group_root_377c25eb",
	"row": "radio-group_row_d4d91e5e"
};
export default ___CSS_LOADER_EXPORT___;
