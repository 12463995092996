// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".error-list-banner_list_1db8be25 {\n  margin: 2px 0 0 0;\n  padding-inline-start: 24px;\n}", "",{"version":3,"sources":["webpack://../../libs/shared/src/ui/notifications/error-list-banner/error-list-banner.module.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,0BAA0B;AAC5B","sourcesContent":[".list {\n  margin: 2px 0 0 0;\n  padding-inline-start: 24px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"list": "error-list-banner_list_1db8be25"
};
export default ___CSS_LOADER_EXPORT___;
