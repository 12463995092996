// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[2].use[1]!../../breakpoints.module.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".tabs_tab_f2119943 {\n  min-width: 72px;\n  border: none;\n  background: none;\n  padding: 0;\n  text-align: left;\n  cursor: pointer;\n}\n\n.tabs_tab_f2119943 > span {\n  border-bottom-color: var(--dinoBlue) ;\n}\n\n.tabs_tabCopy_f7c336e3 {\n  display: inline-block;\n  text-transform: none;\n  padding-bottom: 6px;\n  opacity: 0.7;\n}\n\n.tabs_activeCopy_4eb69637 {\n  opacity: 1;\n}\n\n.tabs_tabsIndicator_cd7ca58f {\n  border-bottom: 4px solid transparent;\n}\n\n@media " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["upMd"] + " {\n  .tabs_tab_f2119943 {\n    margin-right: 30px;\n  }\n}\n", "",{"version":3,"sources":["webpack://../../libs/shared/src/ui/navigation/tabs/tabs.module.css"],"names":[],"mappings":"AAEA;EACE,eAAe;EACf,YAAY;EACZ,gBAAgB;EAChB,UAAU;EACV,gBAAgB;EAChB,eAAe;AACjB;;AAEA;EACE,qCAAqC;AACvC;;AAEA;EACE,qBAAqB;EACrB,oBAAoB;EACpB,mBAAmB;EACnB,YAAY;AACd;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,oCAAoC;AACtC;;AAEA;EACE;IACE,kBAAkB;EACpB;AACF","sourcesContent":["@value upMd from '../../breakpoints.module.css';\n\n.tab {\n  min-width: 72px;\n  border: none;\n  background: none;\n  padding: 0;\n  text-align: left;\n  cursor: pointer;\n}\n\n.tab > span {\n  border-bottom-color: var(--dinoBlue) ;\n}\n\n.tabCopy {\n  display: inline-block;\n  text-transform: none;\n  padding-bottom: 6px;\n  opacity: 0.7;\n}\n\n.activeCopy {\n  opacity: 1;\n}\n\n.tabsIndicator {\n  border-bottom: 4px solid transparent;\n}\n\n@media upMd {\n  .tab {\n    margin-right: 30px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"upMd": "" + ___CSS_LOADER_ICSS_IMPORT_0___.locals["upMd"] + "",
	"tab": "tabs_tab_f2119943",
	"tabCopy": "tabs_tabCopy_f7c336e3",
	"activeCopy": "tabs_activeCopy_4eb69637",
	"tabsIndicator": "tabs_tabsIndicator_cd7ca58f"
};
export default ___CSS_LOADER_EXPORT___;
