import { useQuery } from 'react-query';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { getQuotePet, getQuotePolicyState, useQuote } from '@pumpkincare/quotes';
import {
  capitalize,
  CAT,
  DOG,
  getBreedDefinition,
  KITTEN,
  PUPPY,
  UP_TO_5_MONTHS,
  UP_TO_11_MONTHS,
} from '@pumpkincare/shared';

const PLACEHOLDER_DATA = {
  header: '',

  pickerInfo: {
    copy: '',
    imageUrl: '',
  },

  calculator: {
    copy: '',
    iconUrl: '',
    photoUrl: '',
  },

  testimonials: [
    {
      copy: '',
      imageUrl: '',
      signature: '',
    },

    {
      copy: '',
      imageUrl: '',
      signature: '',
    },
  ],

  coverageDetails: {
    header: '',
    coveredSubheader: '',
    insurance: [{ copy: '', imageUrl: '', title: '' }],
    vetSubheader: '',
    vet: [{ copy: '', imageUrl: '', title: '' }],
  },

  pepCallout: {
    header: '',
    copy: '',
    imageUrl: '',
  },

  howItWorks: {
    header: '',
    items: [
      {
        imageUrl: '',
        copy: '',
      },
    ],
  },

  whyPetInsurance: {
    handle: '',
    imageUrl: '',
    items: [{ copy: '', imageUrl: '', title: '' }],
  },

  royalty: {
    header: '',
    description: '',
    imageUrl: '',
    items: {
      title: '',
      description: '',
    },
  },

  lifetimePromise: {
    header: '',
    items: [{ imageUrl: '', title: '', copy: '' }],
  },

  fence: {
    imageUrl: '',
    header: '',
    subheader: '',
    details: [{ copy: '' }],
  },

  legalRefs: [{ copy: '' }],
};

export function buildDefaultContent(pet = {}) {
  const { petName, petBreedSpecies = '', petAge } = pet;
  const isOffspring = petAge === UP_TO_5_MONTHS || petAge === UP_TO_11_MONTHS;

  const breedLabel =
    petBreedSpecies === DOG && isOffspring
      ? 'puppie'
      : petBreedSpecies === CAT && isOffspring
      ? 'kitten'
      : `${petBreedSpecies.toLowerCase()}`;

  return {
    ...PLACEHOLDER_DATA,

    header: `The ultimate pet insurance plan for your super loveable ${
      petBreedSpecies === DOG ? 'pup' : 'kitty'
    } ${capitalize(petName)}.`,

    pickerInfo: {
      imageUrl: '',
    },

    calculator: {
      copy:
        petBreedSpecies === DOG
          ? `Ruh-roh! Imagine if ${petName} swallowed a squeaker toy and needed emergency surgery!<br/><br/>` +
            "Your plan would help you say 'yes' without thinking twice and <b>reimburse you for 90%</b> of the covered vet bill after hitting your deductible."
          : `Meowch! Imagine if ${petName} swallowed some wool yarn and needed emergency surgery!<br/><br/>` +
            "Your plan would help you say 'yes' without thinking twice and <b>reimburse you for 90%</b> of the covered vet bill after hitting your deductible.",

      photoUrl:
        petBreedSpecies === DOG
          ? '/assets/images/photos/plan-selection/photo-dog-bandana.png'
          : '/assets/images/photos/plan-selection/photo-cat-bandana.png',

      iconUrl:
        petBreedSpecies === DOG
          ? '/assets/images/iconImages/plan-selection/icon-accident-dog.png'
          : '/assets/images/iconImages/plan-selection/icon-accident-cat.png',
    },

    coverageDetails: {
      header: `What will ${petName}’s best-in-show Pumpkin Pet Insurance plan cover?`,

      coveredSubheader: `An Extensive List of Accidents & Illnesses:`,

      insurance:
        petBreedSpecies === DOG
          ? [
              {
                copy: 'From swallowed toys and toxic plants to bite wounds and broken bones',
                imageUrl: '/assets/images/covered_accidentillness.png',
                title: `${isOffspring ? PUPPY : DOG} Accidents & Injuries`,
              },
              {
                copy: 'From ear, eye and skin infections to parasites and digestive illnesses',
                imageUrl:
                  petBreedSpecies === CAT
                    ? '/assets/images/cat-ear-infection.png'
                    : '/assets/images/dog-ear-infection.png',
                title: `${isOffspring ? PUPPY : DOG} Illnesses`,
              },
              {
                copy: 'From diabetes and kidney disease to arthritis, asthma and cancer',
                imageUrl: '/assets/images/covered_chronic.png',
                title: 'Chronic Conditions',
              },
            ]
          : [
              {
                copy: 'From swallowed toys and toxic plants to bite wounds and broken bones',
                imageUrl: '/assets/images/covered_accidentillness.png',
                title: `${isOffspring ? KITTEN : CAT} Accidents & Injuries`,
              },
              {
                copy: 'From respiratory and urinary tract infections to parasites and digestive illnesses',
                imageUrl:
                  petBreedSpecies === CAT
                    ? '/assets/images/cat-ear-infection.png'
                    : '/assets/images/dog-ear-infection.png',
                title: `${isOffspring ? KITTEN : CAT} Illnesses`,
              },
              {
                copy: 'From asthma, herpes and hypothyroidism to diabetes, obesity and cancer',
                imageUrl: '/assets/images/covered_chronic.png',
                title: 'Chronic Conditions',
              },
            ],
      unique_insurance:
        petBreedSpecies === DOG
          ? [
              {
                copy: 'From periodontal disease to gingival hyperplasia and stomatitis',
                imageUrl: 'assets/images/covered_dental.png',
                title: 'Dental Illnesses',
              },
              {
                copy: 'From separation anxiety and OCD to aggressive behavior',
                imageUrl: '/assets/images/covered_behavior.png',
                title: 'Behavioral Issues',
              },
              {
                copy: 'From hip dysplasia and epilepsy to heart and neurologic defects',
                imageUrl: '/assets/images/covered_hereditary.png',
                title: 'Hereditary & Congenital Conditions',
              },
            ]
          : [
              {
                copy: 'From periodontal disease to stomatitis and tooth resorption',
                imageUrl: 'assets/images/covered_dental.png',
                title: 'Dental Illnesses',
              },
              {
                copy: 'From compulsive licking and chewing to aggression and separation anxiety',
                imageUrl: '/assets/images/covered_behavior.png',
                title: 'Behavioral Issues',
              },
              {
                copy: 'From kidney disease and heart disease to deafness and eye defects',
                imageUrl: '/assets/images/covered_hereditary.png',
                title: 'Hereditary & Congenital Conditions',
              },
            ],

      vetSubheader: `Eligible Vet Care for Accidents & llnesses:`,

      vet: [
        {
          copy: 'From x-rays and bloodwork to therapies and procedures',
          imageUrl:
            petBreedSpecies === DOG
              ? '/assets/images/diagnositics_dog.png'
              : '/assets/images/diagnositics_cat.png',
          title: 'Diagnostics & Treatments',
        },
        {
          copy: 'From overnight hospital stays to poison control hotline fees',
          imageUrl: '/assets/images/emergency.png',
          title: 'Emergency Care',
        },
        {
          copy: 'From orthopedic surgery to radiation and chemotherapy',
          imageUrl: '/assets/images/surgery.png',
          title: 'Surgery & Specialized care',
        },
        {
          copy: 'From medication for behavioral problems to insulin injections',
          imageUrl: '/assets/images/rx.png',
          title: 'Rx Medications',
        },
      ],

      unique_vet: [
        {
          copy: 'Including both in-clinic and virtual veterinary visits',
          imageUrl: '/assets/images/examfees.png',
          title: 'Accident & Illness Exam Fees',
        },

        isOffspring
          ? {
              copy: 'Including the microchip and implantation procedure',
              imageUrl:
                petBreedSpecies === DOG
                  ? '/assets/images/microchip_dog.png'
                  : '/assets/images/microchip_cat.png',
              title: 'Microchip Implantation',
            }
          : {
              copy: 'From Rx diets for bladder stones to bone & joint supplements',
              imageUrl: '/assets/images/food.png',
              title: 'Rx Food & Supplements*',
            },

        isOffspring
          ? {
              copy: 'From Rx diets for bladder stones to bone & joint supplements',
              imageUrl: '/assets/images/food.png',
              title: 'Rx Food & Supplements*',
            }
          : {
              copy: 'Including alternative therapies like acupuncture and hydrotherapy',
              imageUrl: '/assets/images/rehab_therapies.png',
              title: 'Rehab Therapies',
            },

        isOffspring
          ? {
              copy: 'Including alternative therapies like acupuncture and hydrotherapy',
              imageUrl: '/assets/images/rehab_therapies.png',
              title: 'Rehab Therapies',
            }
          : {
              copy: 'From in-clinic or in-home euthanasia to private cremation and burial',
              imageUrl: '/assets/images/eol.png',
              title: 'End of Life Services',
            },
      ],
    },

    pepCallout: {
      header:
        petBreedSpecies === CAT
          ? `Want refunds for routine wellness care like that vaccine that keeps ${petName} healthy?`
          : isOffspring
          ? `Want refunds for routine wellness care like puppy vaccines that keep ${petName} healthy?`
          : `Want refunds for routine wellness care like vaccines that keep ${petName} healthy?`,
      copy: `Add a Preventive Essentials wellness package to your plan on the next page. It’s not 
      insurance, but an optional benefit you can easily add to your plan.`,
      imageUrl:
        petBreedSpecies === DOG
          ? '/assets/images/welcome-box-dog.png'
          : '/assets/images/welcome-box-cat.png',
    },

    howItWorks: {
      header: 'Here’s how it works.',
      items: [
        {
          imageUrl: '/assets/images/icon_step_1.png',
          copy: `After your coverage starts, visit your vet for care and pay before you leave.`,
        },
        {
          imageUrl: '/assets/images/icon_step_2.png',
          copy: `Submit a claim online with a copy of the vet bill + medical records in
          minutes.`,
        },
        {
          imageUrl: '/assets/images/icon_step_3.png',
          copy: `Get paid back quickly for eligible vet bills via direct deposit or check – your choice!`,
        },
      ],
    },
    whyPetInsurance: {
      handle: `5 reasons why all ${breedLabel}s need pet insurance.`,
      imageUrl: `/assets/images/photos/${
        breedLabel === 'puppie' ? 'puppy' : breedLabel
      }.png`,
      items: [
        {
          copy: `Young, otherwise healthy ${breedLabel}s included!`,
          imageUrl: '/assets/images/iconImages/siren-pluto-gold.png',
          title: '1 in 3 pets need emergency vet treatment each year.',
        },
        {
          copy: `${capitalize(breedLabel)}s get hurt & sick more than you think.`,
          imageUrl: '/assets/images/iconImages/stethoscope-clifford-red.png',
          title:
            petBreedSpecies === CAT
              ? `Nearly 40% of ${breedLabel} owners with a Pumpkin plan file a claim in year one.`
              : petBreedSpecies === DOG && isOffspring
              ? 'Nearly 50% of puppy owners with a Pumpkin plan file a claim in year one.'
              : '56% of dog owners with a Pumpkin plan file a claim in year one.',
        },
        {
          copy: isOffspring
            ? 'And that doesn’t even count unexpected veterinary costs.'
            : petBreedSpecies === DOG
            ? 'And that’s on top of all the other expenses like food, toys, training & grooming!'
            : 'And that’s on top of all the other expenses like food, toys & litter boxes!',
          imageUrl: '/assets/images/iconImages/savings-benji-blue.png',
          title:
            petBreedSpecies === CAT && isOffspring
              ? `The cost of owning a kitten in the first year can be $3,000 or more!`
              : petBreedSpecies === DOG && isOffspring
              ? 'The cost of owning a puppy in the first year can be $4,000 or more!'
              : petBreedSpecies === DOG
              ? '$16,000+ is the average spent on vet visits over a dog’s lifetime.'
              : '$12,000+ is the average spent on vet visits over a cat’s lifetime.',
        },
        {
          copy: 'The cost of ongoing care can be a fortune – insurance can help!',
          imageUrl: '/assets/images/iconImages/dna-clifford-red.png',
          title: 'Hereditary & breed-specific conditions show up at all ages.',
        },
      ],
    },

    preExConditions: isOffspring
      ? `The younger they are, the better their chances of getting future conditions covered.`
      : petBreedSpecies === CAT
      ? `The sooner you do, the better your cat’s chances of getting future conditions covered.`
      : `The sooner you do, the better your dog’s chances of getting future conditions covered.`,
  };
}

function addAsteriskLink(data) {
  if (typeof data === 'object') {
    for (let key in data) {
      if (key === 'title' || key === 'copy') {
        data[key] = data[key].replace(
          /\*$/g,
          "<span style='color: var(--bluesCluesBlue); cursor: pointer' onClick='window.scrollTo(0, document.body.scrollHeight)'>*</span>"
        );
      } else {
        data[key] = addAsteriskLink(data[key]);
      }
    }
  }

  return data;
}

export const BREED_DEFINITION_QUERY = 'breedDefinitionQuery';

export function useBreedDefinition(petId) {
  const { punks594BreedSpeciesOnPlanPage } = useFlags();

  const { data: quoteData } = useQuote();
  const petData = getQuotePet(quoteData)(petId);
  const policyState = getQuotePolicyState(quoteData);
  const { petAge, petBreedCode, petBreedSpecies, petName } = petData ?? {};

  const isOffspring = petAge === UP_TO_5_MONTHS || petAge === UP_TO_11_MONTHS;
  const postfix = {
    Dog: { true: '-Puppy', false: '-Dog' },
    Cat: { true: '-Kitten', false: '-Cat' },
  };

  // FF true  /breed-definitions/Dog~PureBreed~Ainu%20Dog-Dog
  // FF false /breed-definitions/-Dog
  const breedDefinition =
    petBreedSpecies && petBreedCode
      ? (punks594BreedSpeciesOnPlanPage ? petBreedCode : '') +
        postfix[petBreedSpecies][isOffspring]
      : '';

  return useQuery(
    [BREED_DEFINITION_QUERY, breedDefinition, policyState, petName, petAge],

    () =>
      getBreedDefinition(breedDefinition, petName, policyState).then(
        addAsteriskLink
      ),

    {
      enabled: !!breedDefinition && !!policyState && !!petId,
      placeholderData: buildDefaultContent(petData),
      staleTime: Infinity,
    }
  );
}
