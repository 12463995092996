export const UP_TO_5_MONTHS = '8 weeks to 5 months';
export const UP_TO_11_MONTHS = '6 to 11 months';

export const PET_AGE_OPTIONS = [
  { value: UP_TO_5_MONTHS, label: UP_TO_5_MONTHS },
  { value: UP_TO_11_MONTHS, label: UP_TO_11_MONTHS },
  { value: '1', label: '1 year old' },
  { value: '2', label: '2 years old' },
  { value: '3', label: '3 years old' },
  { value: '4', label: '4 years old' },
  { value: '5', label: '5 years old' },
  { value: '6', label: '6 years old' },
  { value: '7', label: '7 years old' },
  { value: '8', label: '8 years old' },
  { value: '9', label: '9 years old' },
  { value: '10', label: '10 years old' },
  { value: '11', label: '11 years old' },
  { value: '12', label: '12 years old' },
  { value: '13', label: '13 years old' },
  { value: '14', label: '14 years old' },
  { value: '15', label: '15 years old' },
  { value: '16', label: '16 years old' },
  { value: '17', label: '17 years old' },
  { value: '18', label: '18 years old' },
  { value: '19', label: '19 years old' },
  { value: '20', label: '20 years old' },
  { value: '21', label: '21 years old' },
  { value: '22', label: '22 years old' },
  { value: '23', label: '23 years old' },
  { value: '24', label: '24 years old' },
  { value: '25', label: '25 years old' },
];
