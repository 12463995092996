// as more marketing channels with non-consistent URLs are added, format the payloads here
import Store from 'store';

import {
  currentISODate,
  diffSince,
  MARKETING_ATTRIBUTION_LOCAL_STORAGE_KEY,
} from '@pumpkincare/shared';

export function checkMarketingAttributionForExpiry() {
  const { expiry, capturedAt } = getMarketingAttribution();

  if (expiry) {
    const timeSinceAttribution = diffSince(capturedAt, expiry.unit);

    if (timeSinceAttribution > expiry.value) {
      clearMarketingAttribution();
    }
  }
}

export function getMarketingAttribution() {
  return Store.get(MARKETING_ATTRIBUTION_LOCAL_STORAGE_KEY) || {};
}

export function setMarketingAttribution(payload) {
  if (!payload || Object.keys(payload).length <= 1) {
    // we don't want to clear attribution information
    // if no utm parameters were passed on a given page.
    // the payload should always contain the date, though
    return;
  }

  Store.set(MARKETING_ATTRIBUTION_LOCAL_STORAGE_KEY, payload);
}

export function setMarketingAttributionProperty(payload) {
  const marketingAttribution = getMarketingAttribution();

  Store.set(MARKETING_ATTRIBUTION_LOCAL_STORAGE_KEY, {
    ...marketingAttribution,
    ...payload,
  });
}

export function clearMarketingAttribution() {
  Store.remove(MARKETING_ATTRIBUTION_LOCAL_STORAGE_KEY);
}

export function determineMarketingChannelPayload(params) {
  const timedParams = {
    ...params,
    capturedAt: currentISODate(),
  };

  // first parse non-utm parameters to find source
  if (timedParams.cjevent) {
    return formatCjParameters(timedParams);
  }

  if (timedParams.transaction_id) {
    return formatTuneParameters(timedParams);
  }

  if (timedParams.utm_source === 'bxa2') {
    return formatBrandXParameters(timedParams);
  }

  return timedParams;
}

function formatCjParameters(params) {
  return {
    source: 'cj',
    content: params.cjevent,
    capturedAt: params.capturedAt,
    expiry: {
      value: 30,
      unit: 'days',
    },
  };
}

function formatTuneParameters(params) {
  const whiteList = [
    'aff_sub',
    'aff_sub2',
    'aff_sub3',
    'aff_sub4',
    'aff_sub5',
    'adv_sub2',
    'adv_sub3',
    'adv_sub4',
    'adv_sub5',
    'adv_unique1',
    'adv_unique2',
    'adv_unique3',
    'adv_unique4',
    'adv_unique5',
    'aff_id',
    'promo_code',
    'goal_id',
    'goal_ref',
    'offer_id',
  ];

  const affiliateParams = whiteList.reduce((affiliateParamList, whiteListParam) => {
    if (params[whiteListParam]) {
      affiliateParamList =
        affiliateParamList + `&${[whiteListParam]}=${params[whiteListParam]}`;
    }
    return affiliateParamList;
  }, '');

  return {
    source: 'tune',
    affiliateParams: affiliateParams,
    content: params.transaction_id,
    capturedAt: params.capturedAt,
    expiry: {
      value: 30,
      unit: 'days',
    },
  };
}

function formatBrandXParameters(params) {
  return {
    ...params,
    expiry: {
      value: 30,
      unit: 'days',
    },
  };
}
