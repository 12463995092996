export const AUTH_LS_KEY = '@pumpkincare/auth';
export const IDENTITY_LOCAL_STORAGE_KEY = 'identity';
export const ITERABLE_CAMPAIGN_ID = 'iterableEmailCampaignId';
export const ITERABLE_TEMPLATE_ID = 'iterableTemplateId';
export const MARKETING_ATTRIBUTION_LOCAL_STORAGE_KEY = 'marketingAttribution';
export const GRAND_TOTAL_LOCAL_STORAGE_KEY = '@pumpkincare/grandTotal';
export const COMPLETE_QUOTE_ID_LS_KEY = '@pumpkincare/completeQuoteId';
export const MAINTENANCE_MODAL_HAS_SHOWN_COOKIE =
  '@pumpkincare/maintenanceModalHasShown';
export const RENEWAL_MODAL_SHOWN_PETS = '@pumpkincare/renewalModalShownPets';
