import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

// Supported widget types and their template IDs
const widgetTypes = {
  badge: {
    templateId: '53aa8807dec7e10d38f59f32',
    height: '110px',
    width: '180px',
    dataTags: '',
  },
  horizontal: {
    templateId: '5406e65db0d04a09e042d5fc',
    height: '24px',
    width: '100%',
    dataTags: '',
  },
  micro_star: {
    templateId: '5419b732fbfb950b10de65e5',
    height: '24px',
    width: '100%',
    dataTags: '',
  },
  preview: {
    templateId: '5419b637fa0340045cd0c936',
    height: '22px',
    width: '100%',
    dataTags: '',
  },
  drop_down: {
    templateId: '5418052cfbfb950d88702476',
    height: '24px',
    width: '100%',
    dataTags: 'SelectedReview',
  },
  carousel: {
    templateId: '53aa8912dec7e10d38f59f36',
    height: '150px',
    width: '100%',
    dataTags: 'SelectedReview',
  },
};

function Trustpilot({ widgetType }) {
  const ref = useRef(null);
  useEffect(() => {
    // Make sure trustpilot lib is loaded
    if (window.Trustpilot) {
      window.Trustpilot.loadFromElement(ref.current, true);
    }
  }, []);

  return (
    <div
      ref={ref}
      className='trustpilot-widget'
      data-locale='en-US'
      data-template-id={widgetTypes[widgetType].templateId}
      data-businessunit-id='5f0d859849fc9a0001b244cf'
      data-style-height={widgetTypes[widgetType].height}
      data-style-width={widgetTypes[widgetType].width}
      data-theme='light'
      data-stars='1,2,3,4,5'
      data-no-reviews='hide'
      data-scroll-to-list='true'
      data-allow-robots='true'
      data-tags={widgetTypes[widgetType].dataTags}
    >
      <a
        href='https://www.trustpilot.com/review/www.pumpkin.care'
        target='_blank'
        rel='noreferrer noopener'
      >
        Trustpilot
      </a>
    </div>
  );
}

Trustpilot.propTypes = {
  widgetType: PropTypes.oneOf([
    'badge',
    'horizontal',
    'micro_star',
    'preview',
    'drop_down',
    'carousel',
  ]).isRequired,
};

export default Trustpilot;
