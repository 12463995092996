import { useQuery } from 'react-query';

import { getQuoteId, useQuote } from './quote-query';
import { getQuotePricingById } from './quotes-service';

export const PRICING_QUERY = 'pricing';

export const getPricingPetTotals = pricingData => pricingData.pet_totals;
export const getPricingBeforeDiscount = pricingData =>
  pricingData.insurance_price_before_discount;
export const getPricingAfterDiscount = pricingData =>
  pricingData.insurance_price_after_discount;
export const getPricingTransactionFee = pricingData => pricingData.transaction_fee;
export const getPricingTotal = pricingData => pricingData.total_insurance_price;

export function useQuotePricing(annualPay) {
  const { data: quoteData } = useQuote();
  const quoteId = getQuoteId(quoteData);

  return useQuery(
    [PRICING_QUERY, quoteId, annualPay],

    () => getQuotePricingById({ quoteId, annualPay }),

    {
      enabled: !!quoteId,
      staleTime: 5000,
      placeholderData: {
        pet_totals: [
          {
            discounts: [],

            id: '',
            pet_name: '',
            price_after_discount: 0,
            price_before_discount: 0,
          },
        ],

        insurance_price_before_discount: 0,
        insurance_price_after_discount: 0,
        total_insurance_price: 0,
        transaction_fee: 0,
      },
    }
  );
}
