import { PUMPKIN_ASSETS_URL } from '@pumpkincare/config';

// Remember to update this if a new version of this document is added
const DEFAULT_VERSION = 4;
const AGREEMENT_BASE_URL = PUMPKIN_ASSETS_URL + '/pdfs/prevent-customer-agreement';

/**
 *  getCustomerAgreement
 *  @param {String} species (e.g. 'DOG', 'cat' - case insensitive)
 *  @param {Number} preventVersion (e.g. 3.1, 4)
 *  @return {String}
 **/
export function getCustomerAgreement(species, version = DEFAULT_VERSION) {
  if (version >= 4) {
    return `${AGREEMENT_BASE_URL}-${version}.pdf`;
  }

  const lcSpecies = species.toLowerCase();
  if (['dog', 'cat'].some(type => type === lcSpecies) && version >= 1) {
    return `${AGREEMENT_BASE_URL}-${Math.floor(
      version
    )}-${species.toLowerCase()}.pdf`;
  }

  throw Error(
    `No prevent customer agreement for species ${species}, version ${version}`
  );
}

/**
 *  formatCustomerAgreementParams
 *  @param {Number} preventVersion (e.g. '3.1')
 *  @return {String}
 **/
export function formatCustomerAgreementParams(preventVersion) {
  return preventVersion !== DEFAULT_VERSION ? `?v=${preventVersion}` : '';
}
