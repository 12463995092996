import { useRef, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import useGestures from '../../../hooks/use-gestures';
import useKeyboard from '../../../hooks/use-keyboard';
import ArrowNextButton from '../../buttons/arrow-next-button';

import styles from './carousel.module.css';

function Carousel({
  classes,
  children,
  title,
  invertedArrows,
  hasArrows,
  hasTransition,
  onChange,
  'aria-label': ariaLabel,
  index: externalIndex,
}) {
  const [internalIndex, setInternalIndex] = useState(0);
  const ref = useRef(null);
  const isExternal = Number.isInteger(externalIndex);

  const index = isExternal ? externalIndex : internalIndex;

  function handleChange(redirectTo) {
    onChange(redirectTo);
    if (!isExternal) {
      setInternalIndex(redirectTo);
    }
  }

  function handleShift(direction) {
    // "right" is 1, "left" is 0
    const newIndex = direction ? index + 1 : index - 1;

    if (newIndex >= 0 && newIndex < children.length) {
      handleChange(newIndex);
    }
  }

  function handleRightShift() {
    handleShift(1);
  }

  function handleLeftShift() {
    handleShift(0);
  }

  useKeyboard(
    {
      ArrowLeft: handleLeftShift,
      ArrowRight: handleRightShift,
    },
    [index]
  );
  useGestures(ref, {
    onSwipeLeft: handleRightShift,
    onSwipeRight: handleLeftShift,
  });

  return (
    <div className={classNames(styles.root, classes.root)} ref={ref}>
      {title}

      {hasArrows?.left ? (
        <ArrowNextButton
          classes={{ root: classNames(styles.arrowLeft, classes.arrowLeft) }}
          inverted={invertedArrows}
          reverse
          disabled={index === 0}
          onClick={handleLeftShift}
        />
      ) : null}

      <div
        style={{
          width: `${children.length * 100}%`,
          marginLeft: `${index * -100}%`,
        }}
        className={classNames(styles.itemsContainer, {
          [styles.transition]: hasTransition,
        })}
        data-testid='carousel'
        aria-label={ariaLabel}
        aria-roledescription='carousel'
      >
        {children.map((child, idx) => {
          const current = index === idx;
          return (
            <div
              key={idx}
              aria-hidden={!current ? true : undefined}
              aria-roledescription='slide'
              aria-label={`${idx + 1} of ${children.length}`}
              className={classNames(styles.item, classes.item)}
            >
              {child}
            </div>
          );
        })}
      </div>

      {hasArrows?.right ? (
        <ArrowNextButton
          classes={{ root: classNames(styles.arrowRight, classes.arrowRight) }}
          inverted={invertedArrows}
          disabled={index === children.length - 1}
          onClick={handleRightShift}
        />
      ) : null}

      <ul className={classNames(styles.indicators, classes.indicators)}>
        {children.map((item, key) => {
          const isActive = index === key;
          const className = classNames(styles.indicator, classes.indicator, {
            [styles.indicatorActive]: isActive,
            [classes.indicatorActive]: isActive,
            [styles.indicatorInactive]: !isActive,
            [classes.indicatorInactive]: !isActive,
          });

          return (
            <li key={key} className={className} onClick={() => handleChange(key)}>
              <button className={styles.screenreader}>
                <span>Slide {key + 1}</span>
              </button>
            </li>
          );
        })}
      </ul>
    </div>
  );
}

Carousel.defaultProps = {
  title: null,
  classes: {},
  onChange: () => {},
  hasArrows: { left: true, right: true },
  invertedArrows: true,
  hasTransition: true,
};

Carousel.propTypes = {
  classes: PropTypes.shape({
    arrowLeft: PropTypes.string,
    arrowRight: PropTypes.string,
    indicator: PropTypes.string,
    indicators: PropTypes.string,
    indicatorActive: PropTypes.string,
    indicatorInactive: PropTypes.string,
    item: PropTypes.string,
  }),

  title: PropTypes.node,
  hasArrows: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({ left: PropTypes.bool, right: PropTypes.bool }),
  ]),
  invertedArrows: PropTypes.bool,
  hasTransition: PropTypes.bool,
  onChange: PropTypes.func,
  index: PropTypes.number,
  'aria-label': PropTypes.string,
};

export default Carousel;
