import { useQuery } from 'react-query';

import { validateDiscount } from './discounts-service';

const DISCOUNT_QUERY = 'discount';

const initialData = {
  id: null,
  type: null,
};

export const getDiscountId = data => data.id;

export function useDiscount(passcode = '', options) {
  return useQuery(
    DISCOUNT_QUERY,

    () => validateDiscount(passcode),

    {
      enabled: false,
      initialData,
      retry: false,
      staleTime: Infinity,

      ...options,
    }
  );
}
