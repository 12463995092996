import { useEffect } from 'react';

import { captureException } from '../utils/error-tracking';

// dependencies are messed up in this one and need to be fixed
export default function useKeyboard(actionMap, dependencies) {
  function handleKeyDown({ key }) {
    const action = actionMap[key];

    if (action && typeof action !== 'function') {
      captureException(`Key ${key} does not point to a function`);
    } else if (action) {
      actionMap[key] && actionMap[key]();
    }
  }

  return useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, dependencies);
}
