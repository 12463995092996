import PropTypes from 'prop-types';

import { getMarketingAttribution } from '../../marketing-utils';

const iframeProperties = {
  Lead: {
    containerTagId: '37387',
    type: '420988',
    amount: '0',
    postBackURL: 'https://pumpkin.go2cloud.org/aff_lsr?',
  },
  Sale: {
    containerTagId: '37386',
    type: '420987',
    postBackURL:
      'https://pumpkin.go2cloud.org/aff_goal?a=lsr&goal_name=sale&adv_id=0',
  },
};

function TunePixel({ page, quoteId, checkoutTotal }) {
  const { source, content, affiliateParams } = getMarketingAttribution();
  const { amount, postBackURL } = iframeProperties[page];
  let amt1 = amount;

  if (!source || source !== 'tune') {
    return null;
  }

  if (!amount) {
    amt1 = checkoutTotal;
  }

  let additionalParams = affiliateParams;

  if (page === 'Sale') {
    additionalParams = additionalParams + `&amount=${amt1}`;
  }

  const frameSrc = `${postBackURL}&transaction_id=${content}&adv_sub=${quoteId}${additionalParams}`;

  return (
    <iframe
      src={frameSrc}
      height='1'
      width='1'
      frameBorder='0'
      scrolling='no'
      name='tune_conversion'
    />
  );
}

export default TunePixel;

TunePixel.propTypes = {
  checkoutTotal: PropTypes.number.isRequired,
  page: PropTypes.string.isRequired,
  quoteId: PropTypes.string.isRequired,
};
