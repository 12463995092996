import { useQuery } from 'react-query';

import { getCookie, getIsCompletePet } from '@pumpkincare/shared';

import { QUOTE_ID_COOKIE_NAME } from './constants';
import { getQuoteById } from './quotes-service';

export const QUOTE_QUERY = 'quote';

export const getQuoteActivePet = quote => activeId =>
  getQuotePet(quote)(activeId) ?? getQuotePets(quote).find(pet => !!pet.petAge);
export const getQuoteCompletePets = quote => quote.pets.filter(getIsCompletePet);
export const getQuoteEmail = quote => quote.email;
export const getQuoteFirstName = quote => quote.firstName;
export const getQuoteId = quote => quote.id;
export const getQuoteIsRegistered = quote => quote.isRegistered;
export const getQuoteLastName = quote => quote.lastName;
export const getQuotePet = quote => petId =>
  getQuotePets(quote).find(pet => pet.id === petId);
export const getQuotePets = quote => quote.pets;
export const getQuotePetHasPrevent = quote => petId =>
  !!getQuotePet(quote)(petId)?.hasPrevent;
export const getQuotePolicyState = quote => quote.policyState;
export const getQuotePolicyZipCode = quote => quote.policyZipcode;
export const getQuoteStatus = quote => quote.status;
export const getQuoteTrackingId = quote => quote.trackingId;
export const getQuoteTransactionFee = quote => quote.transactionFee;
export const getQuotePartner = quote => quote.partner;
export const getQuoteVet = quote => quote.vet;
export const getQuoteVetId = quote => quote.vet.id;
export const getQuoteVetName = quote => quote.vet.vet_name;

export function useQuote({
  quoteId = getCookie(QUOTE_ID_COOKIE_NAME),
  select,
} = {}) {
  return useQuery(
    [QUOTE_QUERY, quoteId],

    ({ signal }) => getQuoteById({ quoteId, signal }),

    {
      enabled: !!quoteId,

      placeholderData: {
        email: '',
        firstName: '',
        id: quoteId,
        isRegistered: false,
        lastName: '',
        partner: '',
        pets: [],
        policyZipcode: '',
        policyState: '',
        status: '',
        trackingId: '',
        transactionFee: 0,
        vet: {},
      },

      retry: 3,
      select,
    }
  );
}
