import {
  getQuotePartner,
  getQuotePet,
  getQuotePolicyState,
  useQuote,
} from '@pumpkincare/quotes';
import { EMBARK, useEmbarkDiscount } from '@pumpkincare/shared';
import { getUserId, useUssr } from '@pumpkincare/user';

import getPetPlanData from './get-pet-plan-data';
import { usePlan } from './plan-query';
import { usePolicyOptions } from './policy-options-query';
import { useRecommendations } from './recommendations-query';

function usePetPlanData({ petId, identityId, vetId }) {
  const { data: quoteData, isFetched: isQuoteFetched } = useQuote();
  const petData = getQuotePet(quoteData)(petId);
  const policyState = getQuotePolicyState(quoteData);
  const partner = getQuotePartner(quoteData);

  const {
    data: policyOptionsData,
    isFetching: isPolicyOptionsFetching,
    isFetched: isPolicyFetched,
    status: policyOptionsStatus,
  } = usePolicyOptions(petId);

  const {
    data: recommendationsData,
    isFetching: isRecommendationsFetching,
    isFetched: isRecommendationsFetched,
  } = useRecommendations(identityId, petData?.petBreedSpecies);

  const { data: userData } = useUssr();
  const userId = getUserId(userData);

  const {
    data: planData,
    isFetching: isPlanFetching,
    isFetched: isPlanFetched,
  } = usePlan(petData?.petAge, petData?.petBreedSpecies, userId, vetId);

  const { isSuccess: isEmbarkDiscountRequestSuccess } = useEmbarkDiscount(
    policyState,
    partner
  );
  const isEmbarkDiscountSuccess =
    (quoteData.partner === EMBARK && isEmbarkDiscountRequestSuccess) ||
    quoteData.partner !== EMBARK;

  const isFetching =
    isPlanFetching || isPolicyOptionsFetching || isRecommendationsFetching;
  const isFetched =
    isQuoteFetched &&
    isPolicyFetched &&
    isRecommendationsFetched &&
    isPlanFetched &&
    isEmbarkDiscountSuccess;
  const isReady = isFetching === false && isFetched;

  const data = isReady
    ? getPetPlanData(petData, planData, policyOptionsData, recommendationsData)
    : {
        activeValues: {
          deductible: '',
          copay: '',
          annual_limit: '',
        },

        infoContainer: {
          whyDoWeRecommend: {
            lineItems: [],
          },
        },

        insurancePicker: {
          prices: [],
          leversTitle: '',
          availableLevers: [],
          onlyOptionSubtitles: '',
          recommendations: [],
          activePrice: '',
        },

        coverageDetails: {
          header: '',
          detailsTitle: '',
          recommendedPlan: {},
          recommendedPlanOrder: [],
        },

        submitButton: {
          selectedPolicyPrice: 0,
          preventPrice: 0,
          totalPrice: 0,
        },
      };

  return {
    data,
    isFetching,
    isReady,
    isSuccess: isFetched,
  };
}

export default usePetPlanData;
