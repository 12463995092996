import { CAT } from '@pumpkincare/shared';

import { CHECKOUT, PLAN_SELECTION, QUOTE_FLOW, SHOPPING_CART } from './categories';
import {
  CLOSE_CLAIM_PRE_X_MODAL,
  DUPLICATE_PURCHASE_CONTINUE_WITH_DUPS,
  DUPLICATE_PURCHASE_MODAL_DELETE_DUPS,
  DUPLICATE_PURCHASE_MODAL_LOADED,
  INITIATE_EXPERIMENT,
  PLAN_SELECTION_VERSION,
  STARTED_QUOTE,
  VISITED_CHECKOUT,
} from './events';

export function initiateExperiment(category, label) {
  return {
    category,
    event: INITIATE_EXPERIMENT,
    label,
  };
}

export function planSelectionVersion(
  annual_limit,
  copay,
  deductible,
  pepVersion,
  vetId
) {
  return {
    category: PLAN_SELECTION,
    event: PLAN_SELECTION_VERSION,
    pep_version: pepVersion,
    pep_vet: vetId,
    rec_copay: copay,
    rec_deductible: deductible,
    rec_annual_limit: annual_limit,
  };
}

export function claimPreExModalCloseExperiment(closeType, carouselPage) {
  return {
    event: CLOSE_CLAIM_PRE_X_MODAL,
    closeType: closeType,
    carouselPage: carouselPage,
  };
}

export function quoteFlowStartedQuote() {
  return {
    category: QUOTE_FLOW,
    event: STARTED_QUOTE,
  };
}

export function quoteFlowVisitedCheckout(
  totalPrice,
  completedPets = [],
  currency = 'usd'
) {
  let numberOfDogs = 0;
  let numberOfCats = 0;

  completedPets.forEach(pet =>
    pet.petBreedSpecies == CAT ? numberOfCats++ : numberOfDogs++
  );

  return {
    category: QUOTE_FLOW,
    event: VISITED_CHECKOUT,
    currency,
    num_items: completedPets.length,
    num_dogs: numberOfDogs,
    num_cats: numberOfCats,
    value: parseFloat(totalPrice),
  };
}

export function multiPetCartExperiment(flag) {
  return {
    category: 'Exp12_Multi_Pet_Shopping_Bag',
    event: INITIATE_EXPERIMENT,
    label: flag ? 'B_Shopping_Bag' : 'A_No_Bag',
  };
}

export function duplicatePurchaseModalLoaded() {
  return {
    category: CHECKOUT,
    label: 'Duplicate Pet Popup',
    event: DUPLICATE_PURCHASE_MODAL_LOADED,
  };
}

export function duplicatePurchaseModalContinueWithDups() {
  return {
    category: CHECKOUT,
    label: 'Duplicate Pet Popup',
    event: DUPLICATE_PURCHASE_CONTINUE_WITH_DUPS,
  };
}

export function duplicatePurchaseModalDeleteDups() {
  return {
    category: CHECKOUT,
    label: 'Duplicate Pet Popup',
    event: DUPLICATE_PURCHASE_MODAL_DELETE_DUPS,
  };
}

export function cartButtonClick() {
  return {
    category: PLAN_SELECTION,
    event: 'Click Shopping Bag',
    label: PLAN_SELECTION,
  };
}

export function cartItemEdit() {
  return {
    category: SHOPPING_CART,
    event: 'Click Adjust Plan',
    label: 'Edit Plan',
  };
}

export function cartItemCreate() {
  return {
    category: SHOPPING_CART,
    event: 'Click Adjust Plan',
    label: 'Create Quote',
  };
}

export function cartItemRemove() {
  return {
    category: SHOPPING_CART,
    event: 'Click Remove Pet',
    label: SHOPPING_CART,
  };
}

export function cartCheckout() {
  return {
    category: SHOPPING_CART,
    event: 'Click Checkout',
    label: SHOPPING_CART,
  };
}

export function cartAddPet() {
  return {
    category: SHOPPING_CART,
    event: 'Click Create Plan',
    label: SHOPPING_CART,
  };
}

export function renewalsModalClose(closeType, carouselPage, modalType) {
  return {
    category: 'Exp13_Renewals_Modal',
    event: 'Close Modal',
    closeType: closeType,
    carouselPage: carouselPage,
    modalType: modalType,
  };
}

export function renewalsModalSkipToPlan(carouselPage, modalType) {
  return {
    category: 'Exp13_Renewals_Modal',
    event: 'Skip to Plan Details',
    carouselPage: carouselPage,
    modalType: modalType,
  };
}

export function renewalsModalPageView(carouselPage, modalType) {
  return {
    category: 'Exp13_Renewals_Modal',
    event: 'View Renewals Carousel Page',
    carouselPage: carouselPage,
    modalType: modalType,
  };
}

export function errorBannerShow({ title, message, code }) {
  return {
    category: 'Error_Validations',
    event: 'Error Banner Shown',
    errorTitle: title,
    errorMsg: message?.toString(),
    error: code || '',
  };
}

export function questionSetExperiment(qsId, qsDescription) {
  return {
    category: 'Exp_QuestionSets',
    event: INITIATE_EXPERIMENT,
    label: qsDescription,
    qsId: qsId,
  };
}

export function gaMarketingAttribution({ event, ...data }) {
  return {
    category: QUOTE_FLOW,
    event,
    data,
  };
}

export function trustPilotExperiment(flag) {
  return {
    category: 'Trustpilot_Widget',
    event: INITIATE_EXPERIMENT,
    label: flag ? 'b_test_widget' : 'a_control_nowidget',
  };
}

export function checkoutViewExperimentOne(flag) {
  return {
    category: 'Checkout_Experiment 1',
    event: INITIATE_EXPERIMENT,
    label: flag ? 'b_test1' : 'a_control1',
  };
}

export function checkoutViewExperimentTwo(flag) {
  return {
    category: 'Checkout_Experiment 2',
    event: INITIATE_EXPERIMENT,
    label: flag,
  };
}
