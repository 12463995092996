import { useQuery } from 'react-query';

import { getQuoteId, useQuote } from './quote-query';
import { getDiscounts } from './quotes-service';

export const COST_QUERY = 'cost';

export function useCost() {
  const { data: quoteData } = useQuote();
  const quoteId = getQuoteId(quoteData);

  return useQuery(
    [COST_QUERY, quoteId],

    () => getDiscounts(quoteId),

    {
      enabled: !!quoteId,
      placeholderData: [],
      staleTime: 5000,
    }
  );
}
