import { PET_AGE_OPTIONS } from '../constants/pet-ages';
import { formatISODate } from './date-utils';

const format = 'mm/dd/yyyy';
const match = new RegExp(
  format
    .replace(/(\w+)\W(\w+)\W(\w+)/, '^\\s*($1)\\W*($2)?\\W*($3)?([0-9]*).*')
    .replace(/m|d|y/g, '\\d')
);
const replace = '$1/$2/$3$4'.replace(/\//g, format.match(/\W/));

export function formatPetAge(petAge) {
  return PET_AGE_OPTIONS.find(({ value }) => value === petAge).label;
}

export function formatOrdinal(n) {
  const number = parseInt(n);

  if (!Number.isInteger(n) || isNaN(number) || number <= 0) {
    return null;
  }

  const suffixes = ['th', 'st', 'nd', 'rd'];
  const value = number % 100;

  const suffix = suffixes[(value - 20) % 10] || suffixes[value] || suffixes[0];

  return number + suffix;
}

export function formatDate(input) {
  return input
    .replace(/(^|\W)(?=\d\W)/g, '$10') // padding
    .replace(match, replace) // fields
    .replace(/(\W)+/g, '$1'); // remove repeats
}

export function formatMonthlyStatementsGroupTitle(petNames) {
  if (!Array.isArray(petNames) || petNames.length === 0) {
    return null;
  }

  if (petNames.length <= 2) {
    return `${petNames.join(' & ')}'s Plan`;
  }

  const names = `${petNames[0]}, ${petNames[1]} & ${petNames[2]}`;

  const extraPets = petNames.length - 3;

  switch (extraPets) {
    case 0:
      return `${names}'s Plan`;
    case 1:
      return `${names} + 1 Other's Plan`;
    default:
      return `${names} + ${extraPets} Others' Plan`;
  }
}

export function formatCoverageStartDate(dateStr) {
  if (!dateStr) {
    return null;
  }

  return {
    long: formatISODate(dateStr, { inputTimezone: 'local', format: 'MMMM D, YYYY' }),
    mmddyyyy: formatISODate(dateStr, {
      inputTimezone: 'local',
      format: 'MM/DD/YYYY',
    }),
  };
}

export function capitalize(str = '') {
  return str.charAt(0).toUpperCase() + str.substring(1);
}
