import PropTypes from 'prop-types';

import { diffSince } from '@pumpkincare/shared';

import { getMarketingAttribution } from '../../marketing-utils';

function BrandXPixel({ environment, quoteId }) {
  const { utm_source, capturedAt = 0 } = getMarketingAttribution();
  const daysSinceAttribution = diffSince(capturedAt, 'days');

  // only include brandx pixel for traffic received by them in the last 30 days
  if (!(quoteId && utm_source === 'bxa2' && daysSinceAttribution <= 30)) {
    return null;
  }

  const transactionId = `${environment}-${quoteId}`;

  const frameSrc = `https://t1.webbconnected.com/p.ashx?a=341&e=362&t=${encodeURIComponent(
    transactionId
  )}`;

  return <iframe src={frameSrc} height='1' width='1' frameBorder='0' />;
}

export default BrandXPixel;

BrandXPixel.propTypes = {
  environment: PropTypes.string.isRequired,
  quoteId: PropTypes.string.isRequired,
};
