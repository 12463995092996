import { createContext, useCallback, useContext, useReducer } from 'react';

const BANNERS_PROVIDER_ERROR = new Error(
  'The hook must be used within a BannersProvider'
);

const Banners = createContext([]);

function bannersReducer(state = [], action = {}) {
  const { payload, type } = action;

  switch (type) {
    case 'addBanner': {
      return state.find(elem => elem === payload) ? state : [...state, payload];
    }

    case 'removeBanner': {
      return state.filter(banner => banner !== payload);
    }

    case 'removeAllBanners': {
      return [];
    }

    default: {
      return state;
    }
  }
}

function addBanner(payload) {
  return { type: 'addBanner', payload };
}

function removeBanner(payload) {
  return { type: 'removeBanner', payload };
}

function removeAllBanners() {
  return { type: 'removeAllBanners' };
}

export function BannersProvider({ children, initialState = [] }) {
  const [state, dispatch] = useReducer(bannersReducer, initialState);

  return <Banners.Provider value={{ state, dispatch }}>{children}</Banners.Provider>;
}

export function useBanners() {
  const context = useContext(Banners);

  if (context === undefined) {
    throw BANNERS_PROVIDER_ERROR;
  }

  const { state, dispatch } = context;

  return {
    banners: state,
    addBanner: useCallback(banner => dispatch(addBanner(banner)), [dispatch]),
    removeBanner: useCallback(banner => dispatch(removeBanner(banner)), [dispatch]),
    removeAllBanners: useCallback(() => dispatch(removeAllBanners()), [dispatch]),
  };
}
