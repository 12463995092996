import classNames from 'classnames';
import PropTypes from 'prop-types';

import styles from './circle.module.css';

function Circle({ size, fill, classes = {} }) {
  const sizeStr = size + '';
  const radiusStr = size / 2 + '';

  return (
    <div className={classNames(styles.root, classes.root)}>
      <svg
        width={sizeStr}
        height={sizeStr}
        viewBox={`0 0 ${sizeStr} ${sizeStr}`}
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <circle
          cx={radiusStr}
          cy={radiusStr}
          r={radiusStr}
          className={styles[fill]}
        />
      </svg>
    </div>
  );
}

Circle.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string,
  }),
  size: PropTypes.number.isRequired,
  fill: PropTypes.oneOf(['bluesCluesBlue', 'cliffordRed', 'plutoGold', 'scoutNavy'])
    .isRequired,
};

export default Circle;
