import classNames from 'classnames';

import Breakpoints from '../../breakpoints.module.css';
import Global from '../../global.module.css';
import Palette from '../../palette.module.css';
import Typography from '../../typography.module.css';

function GlobalStylesInject() {
  return (
    <div
      className={classNames(
        Breakpoints.inject,
        Palette.inject,
        Typography.inject,
        Global.inject
      )}
    />
  );
}

export default GlobalStylesInject;
