import PropTypes from 'prop-types';

import ChevronLeft from '../../icons/chevron-left';
import RouteLink from '../route-link';

import styles from './simple-header.module.css';

function SimpleHeader({ returnLink, returnCopy, headerCopy }) {
  return (
    <div className={styles.root}>
      <RouteLink to={returnLink} className={styles.link}>
        <ChevronLeft />

        <div className={styles.hidden}>{returnCopy}</div>
      </RouteLink>

      <h1 className={styles.heading}>{headerCopy}</h1>
    </div>
  );
}

SimpleHeader.defaultProps = {
  headerCopy: 'pumpkin',
  returnCopy: 'Back',
};

SimpleHeader.propTypes = {
  headerCopy: PropTypes.string,
  returnCopy: PropTypes.string,
  returnLink: PropTypes.string.isRequired,
};

export default SimpleHeader;
