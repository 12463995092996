import PropTypes from 'prop-types';

import Body2 from '../../typography/body-2';

import styles from './notify-message.module.css';

function NotifyMessage({ message, icon }) {
  return (
    <div className={styles.container}>
      <div className={styles.iconContainer}>
        <img className={styles.icon} aria-hidden='' alt='' src={icon} />
      </div>
      <Body2 className={styles.message}>{message}</Body2>
    </div>
  );
}

NotifyMessage.defaultProps = {
  message: '',
  icon: '',
};

NotifyMessage.propTypes = {
  message: PropTypes.string,
  icon: PropTypes.string,
};

export default NotifyMessage;
