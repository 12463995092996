const breakpoints = {
  values: {
    xs: 0,
    sm: 600,
    md: 960,
    lg: 1280,
    xl: 1920,
  },
};

const screenSizes = {
  isXsDown: {
    max: breakpoints.values.sm,
  },
  isSmDown: {
    min: breakpoints.values.xs,
    max: breakpoints.values.md,
  },
  isMdUp: {
    min: breakpoints.values.md,
  },
};

/*
  matchScreenSizes will return an object similar to screenSizes where each key will point to whether or not the window screen is within the range specified
  mediaBoolean refers to isXsDown / isSmDown / isMdUp
  size refers to min / max
 */
export function matchScreenSizes() {
  return Object.keys(screenSizes).reduce((iterator, mediaBoolean) => {
    const mediaQuery = Object.keys(screenSizes[mediaBoolean]).reduce(
      (query, size) => {
        const value = screenSizes[mediaBoolean][size];
        const newQuery = `(${size}-width: ${value}px)`;
        return query ? `${query} and ${newQuery}` : newQuery;
      },
      ''
    );

    return { ...iterator, [mediaBoolean]: window.matchMedia(mediaQuery).matches };
  }, {});
}
