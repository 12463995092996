// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".progress-bar_track_53b023e4 {\n  width: 100%;\n  height: 15px;\n  border-radius: 8px;\n  background: var(--cujoCream);\n}\n\n.progress-bar_bar_c4cc0c8f {\n  height: 15px;\n  border-radius: 8px;\n  background: var(--benjiBlue);\n}\n", "",{"version":3,"sources":["webpack://../../libs/shared/src/ui/informational/progress-bar/progress-bar.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,4BAA4B;AAC9B;;AAEA;EACE,YAAY;EACZ,kBAAkB;EAClB,4BAA4B;AAC9B","sourcesContent":[".track {\n  width: 100%;\n  height: 15px;\n  border-radius: 8px;\n  background: var(--cujoCream);\n}\n\n.bar {\n  height: 15px;\n  border-radius: 8px;\n  background: var(--benjiBlue);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"track": "progress-bar_track_53b023e4",
	"bar": "progress-bar_bar_c4cc0c8f"
};
export default ___CSS_LOADER_EXPORT___;
