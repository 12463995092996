// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".screenreader_container_33536ca8 {\n    position:absolute;\n    left: -10000px;\n    top:auto;\n    width:1px;\n    height:1px;\n    overflow:hidden;\n}", "",{"version":3,"sources":["webpack://../../libs/shared/src/ui/informational/screenreader/screenreader.module.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,cAAc;IACd,QAAQ;IACR,SAAS;IACT,UAAU;IACV,eAAe;AACnB","sourcesContent":[".container {\n    position:absolute;\n    left: -10000px;\n    top:auto;\n    width:1px;\n    height:1px;\n    overflow:hidden;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "screenreader_container_33536ca8"
};
export default ___CSS_LOADER_EXPORT___;
