import classNames from 'classnames';
import PropTypes from 'prop-types';

import Typography from '../../typography.module.css';
import styles from './switch-input.module.css';

//https://www.sitepoint.com/react-toggle-switch-reusable-component/
function SwitchInput({
  id,
  checked,
  onChange,
  optionLabels,
  small,
  large,
  disabled,
  style,
}) {
  function handleKeyPress(e) {
    if (e.keyCode !== 32) return;

    e.preventDefault();
    onChange(!checked);
  }

  return (
    <div
      className={classNames(styles.container, {
        [styles.small]: small,
        [styles.large]: large,
      })}
      style={style}
    >
      <input
        type='checkbox'
        name={id}
        value={id}
        className={styles.checkbox}
        id={id}
        checked={checked}
        onChange={e => onChange(e.target.checked)}
        disabled={disabled}
        aria-label={id}
      />
      {id ? (
        <label
          className={classNames(styles.label, { [styles.disabled]: disabled })}
          tabIndex={disabled ? -1 : 0}
          onKeyDown={e => handleKeyPress(e)}
          htmlFor={id}
          data-testid='switch-input-label'
        >
          <span
            className={classNames(
              styles.inner,
              Typography.legalBody,
              Typography.bold,
              {
                [styles.disabled]: disabled,
              }
            )}
            data-yes={optionLabels[0]}
            data-no={optionLabels[1]}
            tabIndex={-1}
          />
          <span className={styles.switch} tabIndex={-1} />
        </label>
      ) : null}
    </div>
  );
}

// Set optionLabels for rendering.
SwitchInput.defaultProps = {
  optionLabels: ['✔', '✕'],
};

SwitchInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,

  optionLabels: PropTypes.array,
  small: PropTypes.bool,
  large: PropTypes.bool,
  disabled: PropTypes.bool,
  style: PropTypes.object,
  id: PropTypes.string.isRequired,
};

export default SwitchInput;
