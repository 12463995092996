import classNames from 'classnames';
import PropTypes from 'prop-types';

import Body2 from '../../typography/body-2';
import LoaderButton from '../loader-button';

import styles from './confirmation-panel.module.css';

function ConfirmationPanel({
  classes,
  controlsConfirm,
  controlsDecline,
  disabled,
  errorMessage,
  isLoading,
  confirmationMessage,
  onConfirm,
  onDecline,
  style,
}) {
  return (
    <div className={classes.root} style={style}>
      <Body2 className={classNames(styles.confirmCaption, classes.confirmCaption)}>
        {confirmationMessage}
      </Body2>

      <div className={classNames(styles.controls, classes.controls)}>
        <LoaderButton
          color={controlsConfirm.color}
          classes={{ root: classNames(styles.confirmButton, classes.controlButton) }}
          disabled={disabled}
          isLoading={isLoading}
          onClick={onConfirm}
        >
          {controlsConfirm.text}
        </LoaderButton>

        <LoaderButton
          color={controlsDecline.color}
          classes={{ root: classNames(styles.confirmButton, classes.controlButton) }}
          disabled={disabled || isLoading}
          onClick={onDecline}
        >
          {controlsDecline.text}
        </LoaderButton>
      </div>

      <Body2 className={classNames(styles.errorCaption, classes.errorCaption)}>
        {errorMessage}
      </Body2>
    </div>
  );
}

ConfirmationPanel.defaultProps = {
  classes: {},
  confirmationMessage: 'Woof, are you sure?',
  controlsConfirm: { text: 'Yes', color: 'secondary' },
  controlsDecline: { text: 'No', color: 'secondary' },
};

ConfirmationPanel.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string,
    controlButton: PropTypes.string,
    confirmCaption: PropTypes.string,
    controls: PropTypes.string,
    errorCaption: PropTypes.string,
  }),

  controlsConfirm: PropTypes.exact({
    text: PropTypes.string,
    color: PropTypes.oneOf(['primary', 'secondary']),
  }),
  controlsDecline: PropTypes.exact({
    text: PropTypes.string,
    color: PropTypes.oneOf(['primary', 'secondary']),
  }),
  disabled: PropTypes.bool,
  errorMessage: PropTypes.node,
  isLoading: PropTypes.bool,
  confirmationMessage: PropTypes.node,
  onConfirm: PropTypes.func.isRequired,
  onDecline: PropTypes.func.isRequired,
  style: PropTypes.object,
};

export default ConfirmationPanel;
