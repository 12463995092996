import PropTypes from 'prop-types';

import { Body2, LegalBody } from '@pumpkincare/shared/ui';

import VetClinic from '../vet-clinic';

import styles from './what-vet-clinic.module.css';

function WhatVetClinic({ petName, onChange, className }) {
  return (
    <div className={className}>
      <Body2 className={styles.required}>Who is {petName}’s primary vet?</Body2>

      <LegalBody>
        Note: Updating this vet clinic will update your pet’s primary vet on your
        account. This doesn’t effect your coverage.
      </LegalBody>

      <VetClinic className={styles.vetClinic} onChange={onChange} />
    </div>
  );
}

WhatVetClinic.defaultProps = {
  className: '',
  onChange: () => {},
};

WhatVetClinic.propTypes = {
  petName: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  className: PropTypes.string,
};

export default WhatVetClinic;
