//adapted from https://github.com/giladl82/use-gestures

import { useEffect, useRef, useState } from 'react';
import _ from 'lodash';

class Pointer {
  constructor(touch) {
    this.x = touch.clientX;
    this.y = touch.clientY;
  }
}

function getDistance(p1, p2) {
  const powX = Math.pow(p1.x - p2.x, 2);
  const powY = Math.pow(p1.y - p2.y, 2);

  return Math.sqrt(powX + powY);
}

function getAngleDeg(p1, p2) {
  return (Math.atan2(p1.y - p2.y, p1.x - p2.x) * 180) / Math.PI;
}

/**
 *
 * @param {Object} ref React ref object
 * @param {{
    onSwipeLeft: function,
    onSwipeRight: function,
    onSwipeUp: function,
    onSwipeDown: function,
    onSwipeLeftEnd:function,
    onSwipeRightEnd: function,
    onSwipeUpEnd: function,
    onSwipeDownEnd: function,
    }} handlers
 * @param {{
  minDelta: number
}} options
 */
export default function useGestures(
  ref,
  handlers,
  options = {
    minDelta: 30,
  }
) {
  const [touches, setTouches] = useState(null);
  const [gesture, setGesture] = useState('');

  const initialTouches = useRef(null);

  useEffect(() => {
    const element = ref.current;

    function getCurrentTouches(originalEvent, touches, prevTouch) {
      const firstTouch = initialTouches.current;
      const pointer = new Pointer(touches[0]);

      return {
        preventDefault: originalEvent.preventDefault,
        stopPropagation: originalEvent.stopPropagation,
        ...pointer,
        deltaX: prevTouch ? pointer.x - prevTouch.x : 0,
        deltaY: prevTouch ? pointer.y - prevTouch.y : 0,
        delta: prevTouch ? getDistance(pointer, prevTouch) : 0,
        distance: firstTouch ? getDistance(pointer, firstTouch) : 0,
        angleDeg: prevTouch ? getAngleDeg(pointer, prevTouch) : 0,
      };
    }

    function handleEvent(eventName, event) {
      if (eventName && handlers[eventName]) {
        handlers[eventName](event);
      }
    }

    function handleTouchStart(event) {
      const currentTouches = getCurrentTouches(event, event.touches, null);
      setTouches(currentTouches);
      initialTouches.current = currentTouches;
    }

    function handleTouchMove(event) {
      const currentTouches = getCurrentTouches(event, event.touches, touches);
      setTouches(currentTouches);

      let eventName, theGesture;

      if (
        Math.abs(currentTouches.deltaX) >= options.minDelta &&
        Math.abs(currentTouches.deltaY) < options.minDelta
      ) {
        if (currentTouches.deltaX < 0) {
          eventName = 'onSwipeLeft';
          theGesture = 'swipeLeft';
        } else {
          eventName = 'onSwipeRight';
          theGesture = 'swipeRight';
        }
      } else if (
        Math.abs(currentTouches.deltaX) < options.minDelta &&
        Math.abs(currentTouches.deltaY) >= options.minDelta
      ) {
        if (currentTouches.deltaY < 0) {
          eventName = 'onSwipeUp';
          theGesture = 'swipeUp';
        } else {
          eventName = 'onSwipeDown';
          theGesture = 'swipeDown';
        }
      } else {
        theGesture = '';
      }

      if (eventName) {
        _.debounce((eventName, touches, theGesture) => {
          handleEvent(eventName, touches);
          setGesture(theGesture);
        }, 100)(eventName, touches, theGesture);
      }
    }

    function handleTouchEnd(event) {
      if (gesture) {
        const currentTouches = getCurrentTouches(event, event.changedTouches, null);
        handleEvent(
          `on${gesture.charAt(0).toUpperCase() + gesture.slice(1)}End`,
          currentTouches
        );
      }
    }

    element.addEventListener('touchstart', handleTouchStart);
    element.addEventListener('touchmove', handleTouchMove);
    element.addEventListener('touchend', handleTouchEnd);
    return () => {
      element.removeEventListener('touchstart', handleTouchStart);
      element.removeEventListener('touchmove', handleTouchMove);
      element.removeEventListener('touchend', handleTouchEnd);
    };
  });
}
