import { useMemo } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Typography from '../../typography.module.css';
import styles from './toggle-button.module.css';

function ToggleButton(props) {
  const {
    label,
    icon,
    selected,
    clickHandler,
    onClick,
    direction,
    classes,
    variant = 'default',
    error: { hasError, errorMessage = '' } = {},
    ...rest
  } = props;

  const variants = useMemo(
    () => ({
      isDefault: variant === 'default',
      isPortal: variant === 'portal',
    }),
    [variant]
  );
  const isVertical = direction === 'v';
  const className = classNames(
    Typography.legalBody,
    {
      [styles.toggleButton]: variants.isDefault,
      [styles.toggleButtonPortal]: variants.isPortal,
      [styles.toggleButtonVertical]: isVertical && variants.isDefault,
      [styles.toggleButtonSelected]: selected && variants.isDefault,
      [styles.toggleButtonPortalSelected]: selected && variants.isPortal,
      [styles.error]: hasError || !!errorMessage,
      [classes.selected]: selected,
    },
    classes.root
  );
  const imgClass = classNames(styles.img, classes.img, {
    [styles.imageVertical]: isVertical && variants.isDefault,
    [styles.imageHorizontal]: !isVertical && variants.isDefault,
    [styles.imagePortal]: variants.isPortal,
  });

  const imageWrapperClass = classNames(classes.imageWrapper, {
    [styles.imageWrapper]: variants.isPortal,
  });

  const topIconClass = classNames(classes.topIcon, styles.topIcon, {
    [styles.visible]: selected && variants.isPortal,
  });

  return (
    <button
      {...rest}
      className={className}
      onClick={onClick ?? clickHandler}
      aria-checked={selected}
    >
      <div className={topIconClass} aria-hidden />
      {icon ? (
        <div className={imageWrapperClass}>
          <img className={imgClass} alt='' src={icon} />
        </div>
      ) : null}
      <span>{label}</span>
    </button>
  );
}

ToggleButton.defaultProps = {
  label: '',
  clickHandler: () => {},
  classes: {},
};

ToggleButton.propTypes = {
  label: PropTypes.node,
  icon: PropTypes.string,
  selected: PropTypes.bool,
  clickHandler: PropTypes.func,
  onClick: PropTypes.func,
  direction: PropTypes.oneOf(['v']),
  error: PropTypes.shape({
    hasError: PropTypes.bool,
    errorMessage: PropTypes.string,
  }),
  classes: PropTypes.shape({
    img: PropTypes.string,
    label: PropTypes.string,
    imageWrapper: PropTypes.string,
    topIcon: PropTypes.string,
  }),

  // spread via ...rest
  role: PropTypes.string,
  disabled: PropTypes.bool,
  tabIndex: PropTypes.number,
  'data-testid': PropTypes.string,
};

export default ToggleButton;
