// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".what-vet-clinic_required_690cd43a::after {\n  content: ' *';\n  color: var(--cliffordRed);\n}\n\n.what-vet-clinic_vetClinic_190610a2 {\n  margin-top: 8px;\n}\n", "",{"version":3,"sources":["webpack://../../libs/vets/src/ui/what-vet-clinic/what-vet-clinic.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,yBAAyB;AAC3B;;AAEA;EACE,eAAe;AACjB","sourcesContent":[".required::after {\n  content: ' *';\n  color: var(--cliffordRed);\n}\n\n.vetClinic {\n  margin-top: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"required": "what-vet-clinic_required_690cd43a",
	"vetClinic": "what-vet-clinic_vetClinic_190610a2"
};
export default ___CSS_LOADER_EXPORT___;
