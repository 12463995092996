import { useMemo, useState } from 'react';
import { default as ReactSelect } from 'react-select';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import LegalBody from '../../typography/legal-body';

import Typography from '../../typography.module.css';
import styles from './select.module.css';

function Select(props) {
  const {
    classes,
    customStyles,
    disabled,
    onChange,
    defaultValue,
    label,
    isMobile,
    id,
    variant,
    required,

    error: { errorMessage = '', hasError, isErrorHidden = true },

    ...restInputProps
  } = props;

  // react-select uses emotion for styling
  const selectStyles = {
    input: () => ({
      top: '7px',
      color: 'var(--scoutNavy)',
      position: 'absolute',
      height: '100%',
      padding: '0px',
      width: '313px',
      fontFamily: 'var(--main-font)',
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '16px',
      cursor: 'pointer !important',
    }),

    menu: provided => ({
      ...provided,
      width: '100%',
      maxHeight: isMobile ? '240px' : '216px',
      marginTop: '4px',
      marginBottom: '8px',
      zIndex: 10000,
      overflowY: 'scroll',
      '::-webkit-scrollbar-thumb': {
        background: 'var(--dinoBlue)',
        height: '50px',
      },
      '::-webkit-scrollbar': {
        width: '4px',
        background: 'var(--porkchopGray)',
      },
    }),
    menuList: () => ({
      '::-webkit-scrollbar': {
        width: '4px',
      },
    }),
    placeholder: (provided, state) => {
      return variants.isBordered
        ? {
            fontSize: '16px',
            lineHeight: '24px',
            fontFamily: 'var(--main-font)',
            color: state.isFocused ? 'var(--bluesCluesBlue)' : 'var(--scoutNavy)',
          }
        : {
            top: '7px',
            position: 'absolute',
            width: '313px',
            cursor: 'pointer !important',
            fontFamily: 'var(--main-font)',
            fontStyle: 'normal',
            fontWeight: '400',
            fontSize: '14px',
            color: state.isFocused ? 'var(--bluesCluesBlue)' : 'var(--scoutNavy)',
            ...customStyles.placeholder,
          };
    },
    valueContainer: () => {
      return variants.isBordered
        ? {
            position: 'absolute',
            bottom: '0px',
            height: '40px',
            lineHeight: '24px',
            cursor: 'pointer !important',
            display: 'flex',
            alignItems: 'center',
          }
        : {
            position: 'absolute',
            bottom: '0px',
            height: '35px',
            cursor: 'pointer !important',
          };
    },
    singleValue: () => ({
      top: '7px',
      color: 'var(--scoutNavy)',
      position: 'absolute',
      width: '282px',
      fontFamily: 'var(--main-font)',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '16px',
      ...customStyles.singleValue,
    }),
    dropdownIndicator: (provided, state) => {
      return variants.isBordered
        ? {
            width: '24px',
            display: 'flex',
            position: 'absolute',
            right: '2px',
            color: 'var(--gromitGray)',
          }
        : {
            width: '24px',
            display: 'inline-block',
            position: 'absolute',
            right: '2px',
            top: '3px',
            color: state.isFocused ? 'var(--bluesCluesBlue)' : 'var(--scoutNavy)',
            cursor: 'pointer !important',
            ...customStyles.dropdownIndicator,
          };
    },
    option: (provided, state) => ({
      ...provided,
      fontFamily: 'var(--main-font)',
      paddingBottom: '14px',
      paddingTop: '14px',
      color: state.isFocused ? 'var(--dinoBlue)' : 'var(--scoutNavy)',
      background: 'none',
      cursor: 'pointer',
    }),
    control: (provided, state) => {
      return variants.isBordered
        ? {
            border:
              !isErrorHidden || errorMessage
                ? '2px solid var(--crookshanksRed)'
                : '1px solid var(--pusheenGray)',
            padding: '12px',
            borderRadius: '4px',
          }
        : {
            border: 'none',
            borderBottomWidth: '2px',
            borderBottomStyle: 'solid',
            borderBottomColor: state.isFocused
              ? 'var(--bluesCluesBlue)'
              : 'var(--scoutNavy)',
            padding: '6px 0 7px',
            ...customStyles.control,
          };
    },
  };

  const [isSelected, setSelected] = useState(!!defaultValue);

  const variants = useMemo(
    () => ({
      isDefault: variant === 'default',
      isBordered: variant === 'bordered',
    }),
    [variant]
  );

  function handleChange(option) {
    if (option) {
      setSelected(true);
    }

    onChange(option);
  }

  const labelClassName = classNames(classes.label, Typography.legalBody, {
    [styles.label]: variants.isDefault,
    [styles.borderedLabel]: variants.isBordered,
    [styles.visible]: isSelected,
    [styles.error]: errorMessage || hasError,
  });

  const wrapperClassName = classNames(classes.wrapper, {
    [styles.wrapper]: variants.isDefault,
    [styles.wrapperBordered]: variants.isDefault,
  });

  const containerClassName = classNames(classes.container, {
    [styles.fieldContainer]: variants.isBordered,
  });

  return (
    <div className={containerClassName}>
      <div className={wrapperClassName}>
        <label htmlFor={id} className={labelClassName}>
          {label}
          {required ? <span className={styles.asterisk}> *</span> : null}
        </label>

        <ReactSelect
          isDisabled={disabled}
          styles={selectStyles}
          onChange={handleChange}
          onInputChange={() => {}}
          controlShouldRenderValue
          isClearable={false}
          label={label}
          defaultValue={defaultValue}
          aria-label={label}
          inputId={id}
          {...restInputProps}
        />
      </div>

      {!isErrorHidden || errorMessage ? (
        <LegalBody className={styles.errorMessage}>{errorMessage}</LegalBody>
      ) : null}
    </div>
  );
}

Select.defaultProps = {
  isSearchable: false,
  classes: {},
  disabled: false,
  customStyles: {},
  components: {},
  label: '',
  autoFocus: false,
  tabIndex: 0,
  onChange: () => {},
  error: {},
  required: false,
};

Select.propTypes = {
  classes: PropTypes.shape({
    container: PropTypes.string,
    wrapper: PropTypes.string,
    label: PropTypes.string,
  }),
  customStyles: PropTypes.object,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  defaultValue: PropTypes.any,
  label: PropTypes.string,
  isMobile: PropTypes.bool,
  id: PropTypes.string,

  // ...restInputProps you may need (or are required)
  autoFocus: PropTypes.bool,
  closeMenuOnSelect: PropTypes.bool,
  components: PropTypes.object,
  hideSelectedOptions: PropTypes.bool,
  isMulti: PropTypes.bool,
  isSearchable: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.any.isRequired,
    })
  ).isRequired,
  placeholder: PropTypes.any,
  tabIndex: PropTypes.number,
  value: PropTypes.any,
  error: PropTypes.shape({
    errorMessage: PropTypes.node,
    hasError: PropTypes.bool,
    isErrorHidden: PropTypes.bool,
  }),
  required: PropTypes.bool,
};

export default Select;
