export default function storeObserver(store, selector, changeHandler) {
  const unsubscribe = store.subscribe(storeDidChange);
  // FIXME: Introduce a better solution to handle an empty value in the model
  let currentState = null;

  function storeDidChange() {
    const state = selector(store.getState());

    if (currentState !== state) {
      currentState = state;
      changeHandler(currentState, unsubscribe);
    }
  }

  // Initial state
  storeDidChange();

  return unsubscribe;
}
