import { CAT, DOG, PUPPIES } from './breed-species';

export const PREVENT_1 = `PREVENT_1`;
export const PREVENT_2 = `PREVENT_2`;
export const PREVENT_3 = `PREVENT_3`;
export const PREVENT_4 = `PREVENT_4`;

export const VACCINES_TITLE = `Annual Vaccines`;
export const VACCINES_LABEL = `Annual Vaccine`;
export const PUPPY_VACCINES_LABEL = `Puppy Vaccine`;

export const WELLNESS_TITLE = `Wellness Exam`;
export const WELLNESS_LABEL = `Annual Wellness Exam Fee`;

export const BENEFITS_TITLE = `Benefits`;
export const HEARTWORM_LABEL = `Blood Test for Heartworm & Tick Diseases`;
export const FECAL_LABEL = `Fecal “Poop” Test for Intestinal Worms`;

export const PARASITE_PROTECTION_TITLE = `Monthly Parasite Protection`;
export const PARASITE_PROTECTION_TEXT = {
  [PREVENT_1]: {
    [DOG]: `12 month supply of Simparica® (sarolaner)`,
    [CAT]: `12 month supply of Revolution® Plus (selamectin and sarolaner topical solution)`,
  },

  [PREVENT_2]: {
    [DOG]: `12 months of Simparica Trio™ (sarolaner, moxidectin, and pyrantel chewable tablets)`,
    [PUPPIES]: `12 months of Simparica Trio™ (sarolaner, moxidectin, and pyrantel chewable tablets)`,
    [CAT]: `12 month supply of Revolution® Plus (selamectin and sarolaner topical solution)`,
  },
};
