import PropTypes from 'prop-types';

import { getMarketingAttribution } from '../../marketing-utils';

const iframeProperties = {
  Lead: {
    containerTagId: '37387',
    type: '420988',
    amount: '0',
  },
  Purchase: {
    containerTagId: '37386',
    type: '420987',
  },
};

function CjPixel({ page, quoteId, checkoutTotal }) {
  const { containerTagId, type, amount } = iframeProperties[page];
  const { source, content } = getMarketingAttribution();
  let amt1 = amount;

  if (!source || source !== 'cj') {
    return null;
  }

  if (!amount) {
    amt1 = checkoutTotal;
  }

  const frameSrc = `https://www.emjcd.com/tags/c?containerTagId=${containerTagId}&TYPE=${type}&CID=1560235&ITEM1=${page}&AMT1=${amt1}&QTY1=1&OID=${quoteId}&CURRENCY=USD&CJEVENT=${content}`;

  return (
    <iframe
      src={frameSrc}
      height='1'
      width='1'
      frameBorder='0'
      scrolling='no'
      name='cj_conversion'
    />
  );
}

export default CjPixel;

CjPixel.propTypes = {
  checkoutTotal: PropTypes.number.isRequired,
  page: PropTypes.string.isRequired,
  quoteId: PropTypes.string.isRequired,
};
