// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "./checkedlist.png";
import ___CSS_LOADER_URL_IMPORT_1___ from "./checkedlist-checked.png";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".checked-list_root_6aa53fe7 {\n  display: flex;\n  flex-wrap: wrap;\n  margin: 0;\n  padding: 0;\n}\n\n.checked-list_item_4fbc12bd {\n  width: 100%;\n  margin-bottom: 12px;\n  list-style-position: inside;\n  list-style-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n}\n\n.checked-list_itemChecked_19b27971 {\n  list-style-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n}\n\n.checked-list_label_04153f24 {\n  display: inline-block;\n  opacity: 1 !important;\n  vertical-align: top;\n}\n", "",{"version":3,"sources":["webpack://../../libs/shared/src/ui/organizational/checked-list/checked-list.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,eAAe;EACf,SAAS;EACT,UAAU;AACZ;;AAEA;EACE,WAAW;EACX,mBAAmB;EACnB,2BAA2B;EAC3B,yDAAwC;AAC1C;;AAEA;EACE,yDAAgD;AAClD;;AAEA;EACE,qBAAqB;EACrB,qBAAqB;EACrB,mBAAmB;AACrB","sourcesContent":[".root {\n  display: flex;\n  flex-wrap: wrap;\n  margin: 0;\n  padding: 0;\n}\n\n.item {\n  width: 100%;\n  margin-bottom: 12px;\n  list-style-position: inside;\n  list-style-image: url('checkedlist.png');\n}\n\n.itemChecked {\n  list-style-image: url('checkedlist-checked.png');\n}\n\n.label {\n  display: inline-block;\n  opacity: 1 !important;\n  vertical-align: top;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "checked-list_root_6aa53fe7",
	"item": "checked-list_item_4fbc12bd",
	"itemChecked": "checked-list_itemChecked_19b27971",
	"label": "checked-list_label_04153f24"
};
export default ___CSS_LOADER_EXPORT___;
