import {
  diffSince,
  formatMonthlyStatementsGroupTitle,
  formatNextChargeDate,
  PENDING,
} from '@pumpkincare/shared';

export function transformAddress(address) {
  return {
    city: address.city,
    firstName: address.first_name,
    id: address.id,
    lastName: address.last_name,
    stateProvince: address.state_province,
    street1: address.street_1,
    street2: address.street_2,
    userId: address.user_id,
    zipcode: address.zipcode,
  };
}

export function transformPayment(payment) {
  return {
    fundingSource: {
      accountNumber: payment.funding_source?.account_number ?? '',
      accountType: payment.funding_source?.account_type ?? '',
      failureReason: payment.funding_source?.failure_reason,
      nameOnAccount: payment.funding_source?.name_on_account ?? '',
      received: payment.funding_source?.received,
      responseReceived: payment.funding_source?.response_received,
      routingNumber: payment.funding_source?.routing_number ?? '',
      verified: payment.funding_source?.verified,
    },

    paymentMethod: {
      brand: payment.payment_method.brand,
      expMonth: payment.payment_method.exp_month,
      expYear: payment.payment_method.exp_year,
      funding: payment.payment_method.funding,
      last4: payment.payment_method.last4,
      name: payment.payment_method.name,
    },
  };
}

export function transformUser(user, invoices = []) {
  return {
    billingAddress: transformAddress(
      user.addresses.find(address => address.type === 'billing')
    ),

    email: user.email,
    externalId: `PKN${user.external_id}`,
    firstName: user.first_name,
    id: user.id,
    invoices,
    isChargedAnnually: user.is_charged_annually,
    lapsedSince: user.lapsed_since,
    lastName: user.last_name,
    monthlyChargeDay: user.monthly_charge_day,
    nextChargeDate: user.next_charge_date,
    pets: user.pets,
    phone: user.phone,
    plans: user.pets.reduce((plans, pet) => plans.concat(pet.plans ?? []), []),
    policies: user.pets.reduce(
      (policies, pet) => policies.concat(pet.policies ?? []),
      []
    ),
    reimbursementTarget: user.funding_source,

    shippingAddress: transformAddress(
      user.addresses.find(address => address.type === 'shipping')
    ),

    transactionFee: Math.floor(user.transaction_fee / 100), // returned in cents vs in dollars for quote
  };
}

export function transformMonthlyStatement(statement) {
  const petNames = formatMonthlyStatementsGroupTitle(statement.pet_names);

  const nextChargeDate = statement.next_charge_date
    ? formatNextChargeDate(statement.next_charge_date)
    : null;

  const isLapsed = !!statement.lapsed_since;
  const daysLapsed = isLapsed ? diffSince(statement.lapsed_since, 'days') : 0;

  const balance = (parseFloat(statement.balance) / 100).toFixed(2);

  const textStatus = isLapsed
    ? `Payment is ${daysLapsed} day${daysLapsed > 1 ? 's' : ''} past due`
    : `Current Balance`;

  return {
    petNames,
    nextChargeDate,
    isLapsed,
    daysLapsed,
    balance,
    textStatus,
  };
}

export function transformUserVets(vets) {
  return vets.map(vet => ({
    ...vet,
    permissions: {
      ...vet.permissions,
      needsClaimAction: vet.permissions.can_file_claim_status === PENDING,
      needsDataAction: vet.permissions.can_view_data_status === PENDING,
    },
  }));
}

export function sortMonthlyStatement(invoices, orders = 'asc') {
  return invoices.sort(function (a, b) {
    return orders === 'desc'
      ? new Date(b.created_at) - new Date(a.created_at)
      : new Date(a.created_at) - new Date(b.created_at);
  });
}
