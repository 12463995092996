import _ from 'lodash';
import { EventTypes } from 'redux-segment';

// generateIdentifyMeta generates the metadata to send to segment
export function generateIdentifyMeta(traits, trackingId) {
  const segmentMeta = {
    meta: {
      analytics: {
        eventType: EventTypes.identify,
        eventPayload: {
          traits,
        },
      },
    },
  };
  const enrichMetaWithTrackingId = setAnonymousId(trackingId);
  const enrichMeta = _.flow([enrichMetaWithTrackingId]);
  return enrichMeta(segmentMeta);
}

// generateIdentifyMetaUserId generates the metadata with user id
export function generateIdentifyMetaUserId(traits, userId) {
  return {
    meta: {
      analytics: {
        eventType: EventTypes.identify,
        eventPayload: {
          traits,
          userId,
        },
      },
    },
  };
}

export function generateOrderCompletedMeta(
  email,
  total,
  campaignId = null,
  templateId = null,
  products = []
) {
  return {
    meta: {
      analytics: {
        eventType: EventTypes.track,
        eventPayload: {
          event: 'Order Completed',
          properties: {
            email,
            campaignId,
            products,
            templateId,
            total: parseFloat(total),
            dataFields: {},
          },
        },
      },
    },
  };
}

// setAnonymousId set the anonymous id to segment metadata
function setAnonymousId(trackingId) {
  return meta => {
    _.set(meta, 'meta.analytics.eventPayload.options.anonymousId', trackingId);
    return meta;
  };
}
