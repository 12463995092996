// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ui_text_da5dea99 {\n    color: var(--scoutNavy);\n    -webkit-text-fill-color: var(--scoutNavy);\n\n    font-family: inherit;\n    font-size: 14px;\n\n    height: 24px;\n    margin: 0;\n    overflow: visible;\n    padding: 0;\n}\n\n.ui_text_da5dea99::placeholder {\n    color: var(--pusheenGray);\n    -webkit-text-fill-color: var(--pusheenGray);\n    opacity: 1;\n}\n\n.ui_text_da5dea99:disabled {\n    background: var(--cujoCream);\n    color: var(--pusheenGray);\n    -webkit-text-fill-color: var(--pusheenGray);\n    opacity: 1;\n}\n\n.ui_text_da5dea99:focus::placeholder {\n    color: transparent;\n    -webkit-text-fill-color: transparent;\n}\n\n.ui_textBorderless_92129285 {\n\n    border: none;\n}", "",{"version":3,"sources":["webpack://../../libs/shared/src/ui/input.module.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,yCAAyC;;IAEzC,oBAAoB;IACpB,eAAe;;IAEf,YAAY;IACZ,SAAS;IACT,iBAAiB;IACjB,UAAU;AACd;;AAEA;IACI,yBAAyB;IACzB,2CAA2C;IAC3C,UAAU;AACd;;AAEA;IACI,4BAA4B;IAC5B,yBAAyB;IACzB,2CAA2C;IAC3C,UAAU;AACd;;AAEA;IACI,kBAAkB;IAClB,oCAAoC;AACxC;;AAEA;;IAGI,YAAY;AAChB","sourcesContent":[".text {\n    color: var(--scoutNavy);\n    -webkit-text-fill-color: var(--scoutNavy);\n\n    font-family: inherit;\n    font-size: 14px;\n\n    height: 24px;\n    margin: 0;\n    overflow: visible;\n    padding: 0;\n}\n\n.text::placeholder {\n    color: var(--pusheenGray);\n    -webkit-text-fill-color: var(--pusheenGray);\n    opacity: 1;\n}\n\n.text:disabled {\n    background: var(--cujoCream);\n    color: var(--pusheenGray);\n    -webkit-text-fill-color: var(--pusheenGray);\n    opacity: 1;\n}\n\n.text:focus::placeholder {\n    color: transparent;\n    -webkit-text-fill-color: transparent;\n}\n\n.textBorderless {\n    composes: text;\n\n    border: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"text": "ui_text_da5dea99",
	"textBorderless": "ui_textBorderless_92129285 ui_text_da5dea99"
};
export default ___CSS_LOADER_EXPORT___;
