import PropTypes from 'prop-types';

import { formatCurrency } from '../../../utils/currency-utils';

function MoneyLabel({ value, displayName, isInteger = false }) {
  return (
    <label>
      {displayName ? `${displayName}: ` : ''}{' '}
      {formatCurrency(value, { hideZero: isInteger })}
    </label>
  );
}

MoneyLabel.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  displayName: PropTypes.string,
  isInteger: PropTypes.bool,
};

export default MoneyLabel;
