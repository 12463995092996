import axios from 'axios';

import { POLICY_BASE_API } from '@pumpkincare/config';

import { responseDataBody } from './utils/transformation-utils';

export function getPaperlessAgreement({ terms_version }) {
  return axios
    .get(`${POLICY_BASE_API}/documents/paperless`, {
      params: { terms_version },
    })
    .then(responseDataBody);
}

export function getPartnerBranding(partnerCode) {
  const url = `${POLICY_BASE_API}/partner-quotes/${partnerCode}/branding`;

  return axios.get(url).then(responseDataBody);
}
