import { useCallback, useRef, useState } from 'react';
import classNames from 'classnames';
import _ from 'lodash';

import { requestZipCodeValidity, stateAbbreviations } from '@pumpkincare/shared';
import { Select, TextField, ZipcodeField } from '@pumpkincare/shared/ui';

import styles from './vet-clinic-form.module.css';

function VetClinicForm({ className, onChange, onCancel, defaultValue }) {
  const nameRef = useRef();
  const [address, setAddress] = useState('');
  const [zipcode, setZipcode] = useState('');
  const [city, setCity] = useState('');
  const [phone, setPhone] = useState('');

  const [stateProvince, setStateProvince] = useState('');

  const [zipcodeError, setZipcodeError] = useState(null);

  const debounceOnChange = useCallback(_.debounce(onChange, 500), [onChange]);

  function onDataChange(newData) {
    const payload = {
      ...{
        name: nameRef.current.value,
        address,
        zipcode,
        state: stateProvince,
        city,
        phone,
      },
      ...newData,
    };

    const isNameValid = !!payload.name;
    const isAddressValid = !!payload.address;
    const isZipcodeValid = payload.zipcode.length === 5 && !zipcodeError;
    const isStateValid = !!payload.state;
    const isCityValid = !!payload.city;
    const isPhoneValid = !!payload.phone;

    const isValid =
      isNameValid &&
      isAddressValid &&
      isStateValid &&
      isCityValid &&
      isZipcodeValid &&
      isPhoneValid;

    debounceOnChange({ valid: isValid, payload: payload });
  }

  function handleNameChange({ target: { value } }) {
    onDataChange({ name: value });
  }

  function handleAddressChange({ target: { value } }) {
    setAddress(value);
    onDataChange({ address: value });
  }

  function handleZipcodeChange({ target: { value } }) {
    setZipcode(value);
    onDataChange({ zipcode: value });

    if (value.length != 5 && zipcodeError) {
      setZipcodeError(null);
      onDataChange({ state: '' });
    }

    if (value.length === 5) {
      validateZip(value);
    }
  }

  function handleStateChange({ value }) {
    setStateProvince(value);
    onDataChange({ state: value });
  }

  function handleCityChange({ target: { value } }) {
    setCity(value);
    onDataChange({ city: value });
  }

  function handlePhoneChange({ target: { value } }) {
    setPhone(value);
    onDataChange({ phone: value });
  }

  function validateZip(value) {
    requestZipCodeValidity(value)
      .then(({ state }) => {
        setStateProvince(state);
        setZipcodeError(null);
        onDataChange({ zipcode: value, state: state });
      })
      .catch(() => {
        setStateProvince('');
        setZipcodeError(
          "Hmmm, we can't find that one. Please enter a valid U.S. zip code."
        );
        onDataChange({ zipcode: value, state: '' });
      });
  }

  function handleCancelIconClick() {
    onCancel();
  }

  return (
    <div className={classNames(styles.root, className)}>
      <TextField
        inputRef={nameRef}
        defaultValue={defaultValue}
        onChange={handleNameChange}
        classes={{ container: styles.fullField }}
        endAdornment={{ icon: 'close', onIconClick: handleCancelIconClick }}
        isLabelHidden
        required
      />

      <TextField
        label='Address'
        value={address}
        onChange={handleAddressChange}
        classes={{ container: styles.fullField }}
        required
      />

      <ZipcodeField
        label='Zipcode'
        value={zipcode}
        onChange={handleZipcodeChange}
        error={{ errorMessage: zipcodeError }}
        classes={{ container: styles.halfField }}
        required
      />

      <Select
        options={stateAbbreviations.map(value => ({
          value,
          label: value,
        }))}
        onChange={handleStateChange}
        label={'State *'}
        placeholder={'State *'}
        value={stateProvince && { value: stateProvince, label: stateProvince }}
        classes={{ wrapper: styles.halfField }}
      />

      <TextField
        label='City'
        value={city}
        onChange={handleCityChange}
        classes={{ container: styles.halfField }}
        required
      />

      <TextField
        label='Phone'
        value={phone}
        onChange={handlePhoneChange}
        classes={{ container: styles.halfField }}
        required
      />
    </div>
  );
}

VetClinicForm.defaultProps = {
  onChange: () => {},
  onCancel: () => {},
  name: '',
};

export default VetClinicForm;
