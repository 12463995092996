function formatAddress(address) {
  return {
    first_name: address.firstName.value,
    last_name: address.lastName.value,
    street_1: address.address1.value,
    street_2: address.address2.value,
    zipcode: address.zipcode.value,
    city: address.city.value,
    state: address.state.value,
  };
}

export default function transformFinalize(
  stripeToken,
  quotes,
  user,
  isChargedAnnually,
  termsVersion = null,
  isPaperless = null,
  noopPayload
) {
  const { billingAddress, shippingAddress, isShippingSameAsBilling } = quotes;
  const shippingInformation = formatAddress(shippingAddress);
  const billingInformation = {
    ...(isShippingSameAsBilling
      ? shippingInformation
      : formatAddress(billingAddress)),

    name_on_card: billingAddress.nameShownOnCard.value,
  };

  const result = {
    stripeToken,
    agent_id: user?.agentId,
    phone: billingAddress.phone.value,
    billing_information: billingInformation,
    shipping_information: shippingInformation,
    billed_annually: isChargedAnnually,
    is_paperless: isPaperless,
    terms_version: termsVersion,
    ...noopPayload,
  };

  return result;
}
