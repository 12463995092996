import { useQueries, useQuery } from 'react-query';

import { getQuotePets, getQuoteVetId, useQuote } from '@pumpkincare/quotes';
import { getIsLoggedIn } from '@pumpkincare/shared';
import { getUserId, useUssr } from '@pumpkincare/user';

import { transformPetAgeToMonths } from './plan-utils';
import { getPlan } from './plans-service';

export const PLAN_QUERY = 'plan';

export const getPlanCost = plan => plan.cost;
export const getPlanVersion = plan => plan.version_number;
export const getPlanWebDescription = plan => JSON.parse(plan.web_description);

export function usePlan(petAge, petBreedSpecies, userId, vetId) {
  const petAgeInMonths = transformPetAgeToMonths(petAge);
  const isLoggedIn = getIsLoggedIn();

  return useQuery(
    [PLAN_QUERY, petBreedSpecies, petAgeInMonths, userId, vetId],

    () => getPlan(petBreedSpecies, petAgeInMonths, userId, vetId),

    {
      enabled: !!petBreedSpecies && !!petAgeInMonths && (!!userId || !isLoggedIn),

      placeHolderData: {
        cost: 0,
        definition: {},
        description: '',
        entitlements: [],
        id: '',
        name: '',
        savings: '',
        status: '',
        version_number: '',
        web_description: '',
      },

      staleTime: Infinity,
    }
  );
}

export function usePlans() {
  const { data: quoteData } = useQuote();
  const pets = getQuotePets(quoteData);
  const vetId = getQuoteVetId(quoteData);

  const { data: userId } = useUssr(getUserId);

  const isLoggedIn = getIsLoggedIn();

  const queries = pets.map(({ petAge, petBreedSpecies, id: petId }) => {
    const petAgeInMonths = transformPetAgeToMonths(petAge);

    return {
      queryKey: [PLAN_QUERY, petBreedSpecies, petAgeInMonths, userId, vetId, petId],

      queryFn: () =>
        getPlan(petBreedSpecies, petAgeInMonths, userId, vetId).then(plan => ({
          petId,

          ...plan,
        })),

      enabled: !!petBreedSpecies && !!petAgeInMonths && (!!userId || !isLoggedIn),

      placeHolderData: {
        cost: 0,
        definition: {},
        description: '',
        entitlements: [],
        id: '',
        name: '',
        petId: '',
        savings: '',
        status: '',
        version_number: '',
        web_description: '',
      },

      staleTime: Infinity,
    };
  });

  return useQueries(queries);
}
