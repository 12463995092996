import { useQuery } from 'react-query';

import { postVetClinicSearchWithZip } from './vets-service';

export const VET_CLINICS_QUERY = 'vetClinics';

export function useVetClinics({ searchTerm, zipCode = '', ...options }) {
  return useQuery(
    [VET_CLINICS_QUERY, searchTerm, zipCode],

    () => postVetClinicSearchWithZip(searchTerm, zipCode),

    {
      enabled: !!searchTerm || !!zipCode,
      placeholderData: [],
      staleTime: Infinity,

      ...options,
    }
  );
}
