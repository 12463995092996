export function convertCentsToDollars(value) {
  return Number.isNaN(value) ? value : value / 100;
}

export function convertDollarsToCents(value) {
  return Number.isNaN(value) ? value : value * 100;
}

export function formatCurrency(
  value,
  { hideZero = false, areCents = false, ifNaN = '' } = {}
) {
  if (Number.isNaN(parseFloat(value))) {
    return ifNaN;
  }

  const numberFormat = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: hideZero ? 0 : 2,
  });

  return numberFormat.format(areCents ? value / 100 : value);
}

export function getNumbersFromString(value) {
  const regex = /\d+/g;
  return regex.exec(value);
}
