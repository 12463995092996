import classNames from 'classnames';
import PropTypes from 'prop-types';

import LegalBody from '../../typography/legal-body';

import styles from './checked-list.module.css';

function CheckedList(props) {
  const { classes, columns, data } = props;

  return (
    <ul className={classNames(styles.root, classes.root)}>
      {data.map(({ checked, label }, idx) => {
        const itemClassName = classNames(styles.item, classes.item, {
          [styles.itemChecked]: checked,
        });

        return (
          <li
            key={idx}
            className={itemClassName}
            style={{ width: `${100 / columns}%` }}
          >
            <LegalBody className={styles.label}>{label}</LegalBody>
          </li>
        );
      })}
    </ul>
  );
}

export default CheckedList;

CheckedList.defaultProps = {
  classes: {},
  data: [],
  columns: 1,
};

CheckedList.propTypes = {
  classes: PropTypes.object,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      checked: PropTypes.bool.isRequired,
      label: PropTypes.string.isRequired,
    })
  ),
  columns: PropTypes.number,
};
