import {
  ARF_MEOW_PHONE_NUMBER,
  CONTACT_EMAIL,
  FORMATTED_PHONE_NUMBER,
} from './contacts';

export const BannerType = Object.freeze({
  ERROR: 'error',
  INFO: 'info',
  WARNING: 'warning',
});

export const GENERIC_ERROR = {
  type: BannerType.ERROR,
  title: 'Ruh roh, looks like something went wrong',
  message: `Try creating another quote or contacting our care team at ${CONTACT_EMAIL} or at ${ARF_MEOW_PHONE_NUMBER}.`,
};

export const GENERIC_TEMP_ERROR = {
  type: BannerType.ERROR,
  title: 'Ruh Roh! We’ve encountered a temporary error.',
  message: `Please try again in 30 seconds or reach out to our care team at ${CONTACT_EMAIL}.`,
};

export const GENERIC_CONTACT_ERROR = {
  type: BannerType.ERROR,
  title: 'Ruh roh, looks like something went wrong',
  message: `We’re sorry, we’re not able to process your request. Please try again later or contacting our care team at ${CONTACT_EMAIL} or ${ARF_MEOW_PHONE_NUMBER}`,
};

export const UNVERIFIED_ACCOUNT_ERROR = {
  type: BannerType.ERROR,
  title: 'Unverified Bank Account:',
  message: `We were unable to verify your account. Please update your direct deposit information or contact our customer care team at ${CONTACT_EMAIL} or ${ARF_MEOW_PHONE_NUMBER} (${FORMATTED_PHONE_NUMBER})`,
};

export const GENERIC_PAYMENT_ERROR = {
  type: BannerType.ERROR,
  title: 'We are unable to process your payment.',
  message:
    'There was an error processing your payment. Please review your information and try an alternative card.',
};

export const DUPLICATE_PET_ERROR = {
  type: BannerType.ERROR,
  title: 'We are unable to process your payment.',
  message:
    'Looks like you already purchased a plan or have duplicate plans selected for the same pet. Please review your plan selection.',
};

export const OUTAGE_WARNING = {
  type: BannerType.WARNING,
  icon: '/assets/images/iconImages/tool.png',
  title: 'We’re down for maintenance right meow!',
  message:
    'Purchasing and logging in will be restored at a later time. You will receive an email with your quote details so you can easily pick up where you left off after maintenance.',
};
