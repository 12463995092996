import PropTypes from 'prop-types';

import darkFacebook from './facebook-icon.svg';
import roundedFacebook from './facebook-rounded-icon.png';
import lightFacebook from './facebook-white-icon.svg';
import darkInstagram from './instagram-icon.svg';
import roundedInstagram from './Instagram-rounded-icon.png';
import lightInstagram from './instagram-white-icon.svg';
import darkTiktok from './tiktok-icon.svg';
import roundedTiktok from './tiktok-rounded-icon.png';
import lightTiktok from './tiktok-white-icon.svg';
import darkTwitter from './twitter-icon.svg';
import roundedTwitter from './twitter-rounded-icon.png';
import lightTwitter from './twitter-white-icon.svg';

const icons = {
  dark: {
    facebook: darkFacebook,
    instagram: darkInstagram,
    twitter: darkTwitter,
    tiktok: darkTiktok,
  },
  light: {
    facebook: lightFacebook,
    instagram: lightInstagram,
    twitter: lightTwitter,
    tiktok: lightTiktok,
  },
  rounded: {
    facebook: roundedFacebook,
    instagram: roundedInstagram,
    twitter: roundedTwitter,
    tiktok: roundedTiktok,
  },
};

const socialMediaLinks = [
  {
    name: 'facebook',
    link: 'https://www.facebook.com/pumpkincares',
  },
  {
    name: 'twitter',
    link: 'https://twitter.com/pumpkincares',
  },
  {
    name: 'instagram',
    link: 'https://www.instagram.com/pumpkincares',
  },
  {
    name: 'tiktok',
    link: 'https://www.tiktok.com/@pumpkincares',
  },
];

function SocialMedia({ imageProps, anchorProps, containerProps, type }) {
  const icon = icons[type];
  return (
    <div style={{ marginTop: '12px' }} {...containerProps}>
      {socialMediaLinks.map(({ name, link }) => (
        <a
          key={name}
          href={link}
          target='_blank'
          rel='noopener noreferrer'
          {...anchorProps}
        >
          <img src={icon[name]} alt={name} {...imageProps} />
        </a>
      ))}
    </div>
  );
}

SocialMedia.defaultProps = {
  type: 'dark',
};

SocialMedia.propTypes = {
  anchorProps: PropTypes.shape({
    className: PropTypes.string,
    style: PropTypes.object,
  }),

  containerProps: PropTypes.shape({
    className: PropTypes.string,
    style: PropTypes.object,
  }),

  imageProps: PropTypes.shape({
    className: PropTypes.string,
    style: PropTypes.object,
  }),

  type: PropTypes.oneOf(['dark', 'light', 'rounded']),
};

export default SocialMedia;
