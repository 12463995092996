export function responseDataBody(response) {
  return response.data.body;
}

export function buildAuthHeaders(user) {
  return {
    headers: {
      Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}`,
    },

    response: true,
  };
}
