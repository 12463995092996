import { useEffect } from 'react';

import { Body1, Circle, SocialMedia } from '../../ui';

import {
  ARF_MEOW_PHONE_NUMBER,
  CONTACT_EMAIL,
  FORMATTED_PHONE_NUMBER,
} from '../../constants/contacts';
import { useBanners } from '../../context/banners-context';

import styles from './maintenance-page.module.css';

function MaintenancePage() {
  const { removeAllBanners } = useBanners();

  useEffect(() => {
    removeAllBanners();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.errorPage}>
      <div className={styles.circles}>
        <Circle size={64} classes={{ root: styles.circleYellow }} fill='plutoGold' />
        <Circle
          size={96}
          classes={{ root: styles.circleBlue }}
          fill='bluesCluesBlue'
        />
        <Circle size={152} classes={{ root: styles.circleRed }} fill='cliffordRed' />
      </div>

      <div className={styles.errorPageContent}>
        <h2 className={styles.header}>
          Sorry, we’re down for maintenance right meow.
        </h2>

        <Body1 className={styles.errorText}>
          Purchasing and logging in will be restored at a later time. You will
          receive an email with your quote details so you can easily pick up where
          you left off after maintenance.
        </Body1>

        <h4 className={styles.h4}>Have questions? We’re a paw away.</h4>

        <Body1>
          {`${ARF_MEOW_PHONE_NUMBER} (${FORMATTED_PHONE_NUMBER}) | `}
          {CONTACT_EMAIL}
        </Body1>

        <SocialMedia
          containerProps={{ className: styles.socialLinks }}
          anchorProps={{ className: styles.socialLinksItem }}
          imageProps={{ className: styles.socialLinksImage }}
        />
      </div>
    </div>
  );
}

export default MaintenancePage;
