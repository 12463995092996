import PropTypes from 'prop-types';

import { CAT, DOG } from '../../../constants/breed-species';
import Circle from '../../cosmetic/circle';
import Spinner from '../../informational/spinner';
import Body1 from '../../typography/body-1';

import styles from './loading-screen.module.css';

import fetchingCat from './plan-selection-fetching-cat.png';
import playing from './playing.png';

function LoadingScreen({ title, subtitle, petBreedSpecies }) {
  return (
    <div className={styles.root}>
      <div className={styles.hiddenMobile}>
        <Circle
          size={192}
          classes={{ root: styles.desktopCircle1 }}
          fill='cliffordRed'
        />
        <Circle
          size={94}
          classes={{ root: styles.desktopCircle2 }}
          fill='scoutNavy'
        />
        <Circle
          size={286}
          classes={{ root: styles.desktopCircle3 }}
          fill='bluesCluesBlue'
        />
      </div>

      <div className={styles.hiddenDesktop}>
        <Circle
          size={61}
          classes={{ root: styles.mobileCircle1 }}
          fill='cliffordRed'
        />
        <Circle
          size={29}
          classes={{ root: styles.mobileCircle2 }}
          fill='scoutNavy'
        />
        <Circle
          size={125}
          classes={{ root: styles.mobileCircle3 }}
          fill='plutoGold'
        />
      </div>

      <div className={styles.wrapper}>
        <h1 className={styles.header}>{title}</h1>

        {subtitle ? <Body1 className={styles.body}>{subtitle}</Body1> : null}

        <Spinner size={60} thickness={2} />

        <div className={styles.yorkieContainer}>
          {petBreedSpecies === CAT ? (
            <img alt='rolling cat' src={fetchingCat} className={styles.catImg} />
          ) : (
            <img alt='playing' src={playing} className={styles.dogImg} />
          )}
        </div>
      </div>
    </div>
  );
}

LoadingScreen.defaultProps = {
  subtitle: '',
  petBreedSpecies: DOG,
};

LoadingScreen.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  petBreedSpecies: PropTypes.oneOf(['Cat', 'Dog']),
};

export default LoadingScreen;
