import { Link } from 'react-router-dom';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Typography from '../../typography.module.css';

function RouteLink({ className, children, ...rest }) {
  return (
    <Link className={classNames(Typography.link, className)} {...rest}>
      {children}
    </Link>
  );
}

RouteLink.propTypes = {
  className: PropTypes.string,

  // ...rest via react-router-dom
  to: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      pathname: PropTypes.string,
      search: PropTypes.string,
      hash: PropTypes.string,
    }),
  ]).isRequired,
  replace: PropTypes.bool,

  // ...rest via common native anchor properties
  download: PropTypes.bool,
  rel: PropTypes.string,
  target: PropTypes.string,
  style: PropTypes.object,
  onClick: PropTypes.func,
  role: PropTypes.string,
  id: PropTypes.string,
};

export default RouteLink;
