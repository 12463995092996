import classNames from 'classnames';
import PropTypes from 'prop-types';

import LegalBody from '../../typography/legal-body';

import Typography from '../../typography.module.css';
import styles from './text-area.module.css';

function TextArea({
  classes,
  label,
  error: { errorMessage = '', hasError },
  ...restTextAreaProps
}) {
  const { id, placeholder, required, readOnly } = restTextAreaProps;

  const labelClassName = classNames(classes.label, Typography.legalBody, {
    [styles.error]: errorMessage || hasError,
    [styles.hidden]: !(label || placeholder),
    [styles.readOnly]: readOnly,
  });
  const textAreaClassName = classNames(
    styles.textArea,
    classes.root,
    Typography.body2,
    {
      [styles.error]: errorMessage || hasError,
      [styles.readOnly]: readOnly,
    }
  );

  return (
    <div className={classNames(styles.container, classes.container)}>
      <label htmlFor={id} className={labelClassName}>
        {label || placeholder}
        {required ? <span className={styles.asterisk}>*</span> : null}
      </label>

      <div className={styles.textAreaContainer}>
        <textarea className={textAreaClassName} {...restTextAreaProps} />
      </div>

      <LegalBody className={styles.errorMessage}>{errorMessage}</LegalBody>
    </div>
  );
}

export default TextArea;

TextArea.defaultProps = {
  rows: 2,
  onBlur: () => {},
  onChange: () => {},
  onFocus: () => {},
  error: {},
  classes: {},
};

TextArea.propTypes = {
  classes: PropTypes.object,
  label: PropTypes.any,
  /** hasError will do just a red border, errorMessage will add a red message underneath too */
  error: PropTypes.shape({
    errorMessage: PropTypes.any,
    hasError: PropTypes.bool,
  }),

  // ...restTextAreaProps get spread directly into <textarea>
  id: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  /** default minimum height for the textarea */
  rows: PropTypes.number,
  'data-testid': PropTypes.string,
};
