import { useQuery } from 'react-query';

import { getQuotePolicyState, useQuote } from '@pumpkincare/quotes';

import { getRecommendations } from './plans-service';
import { getProductHasPrevent, useProductsByState } from './products-by-state-query';

export const RECOMMENDATIONS_QUERY = 'recommendations';

export function useRecommendations(identityId, petBreedSpecies = '') {
  const { data: quoteData } = useQuote();
  const policyState = getQuotePolicyState(quoteData);

  const { data: productsByStateData } = useProductsByState(policyState);
  const productHasPrevent = getProductHasPrevent(productsByStateData);

  return useQuery(
    [RECOMMENDATIONS_QUERY, identityId, petBreedSpecies, productHasPrevent],

    () =>
      getRecommendations(identityId, 'plan_page', {
        species: petBreedSpecies.toLowerCase(),
        prevent: productHasPrevent,
      }),

    {
      enabled: !!identityId && !!petBreedSpecies,
      staleTime: Infinity,
    }
  );
}
