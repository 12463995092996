import { useCallback, useState } from 'react';

function useBooleanInput(initialValue = false) {
  const [value, setValue] = useState(initialValue);

  const toggle = useCallback(
    nextValue => {
      if (typeof nextValue === 'boolean') {
        setValue(!!nextValue);
        return;
      }
      setValue(v => !v);
    },
    [setValue]
  );

  return [value, toggle];
}

export default useBooleanInput;
