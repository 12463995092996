import Store from 'store';

import { AUTH_LS_KEY } from '../constants/cookies';
import { captureException } from './error-tracking';

export function setIsLoggedIn(value) {
  try {
    Store.set(AUTH_LS_KEY, value);
  } catch (e) {
    captureException(e);
  }
}

export function getIsLoggedIn() {
  try {
    return !!Store.get(AUTH_LS_KEY);
  } catch (e) {
    captureException(e);
  }
}
