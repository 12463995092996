import classNames from 'classnames';
import PropTypes from 'prop-types';

import styles from './testimonials.module.css';

function Testimonials({ image, shapes, children, classes = {} }) {
  return (
    <div className={classNames(styles.testimonials, classes.root)}>
      <img src={image} className={classNames(styles.image, classes.image)} alt='' />

      {shapes}

      <div className={classNames(styles.text, classes.content)}>{children}</div>
    </div>
  );
}

Testimonials.propTypes = {
  image: PropTypes.string,
  shapes: PropTypes.element,

  classes: PropTypes.shape({
    root: PropTypes.string,
    image: PropTypes.string,
    content: PropTypes.string,
  }),
};

export default Testimonials;
