import { useState } from 'react';
import PropTypes from 'prop-types';
import Store from 'store';

import GlobalBanner from '../global-banner';

import styles from './show-once-banner.module.css';

function ShowOnceBanner({ banner, onError, storeKey }) {
  const isShowOnceBanner = Store.get('isShowOnceBanner') || {};
  const [hasBeenClosed, setHasBeenClosed] = useState(isShowOnceBanner[storeKey]);

  function handleClose() {
    setHasBeenClosed(true);
    Store.set('isShowOnceBanner', { ...isShowOnceBanner, [storeKey]: true });
  }

  return hasBeenClosed ? null : (
    <GlobalBanner
      banner={banner}
      classes={{
        root: styles.bannerRoot,
        closeButton: styles.closeButton,
        bannerIcon: styles.bannerIcon,
      }}
      onClose={handleClose}
      onError={onError}
    />
  );
}

ShowOnceBanner.defaultProps = {
  onError: () => {},
};

ShowOnceBanner.propTypes = {
  storeKey: PropTypes.string.isRequired,
  banner: PropTypes.shape({
    type: PropTypes.oneOf(['error', 'info', 'warning']).isRequired,
    icon: PropTypes.string,
    title: PropTypes.node,
    message: PropTypes.node,
    code: PropTypes.node,
  }).isRequired,
  onError: PropTypes.func,
};

export default ShowOnceBanner;
