import classNames from 'classnames';
import PropTypes from 'prop-types';

import Typography from '../../typography.module.css';

function Superscript1({ children, className = '', ...rest }) {
  return (
    <sup className={classNames(Typography.superscript1, className)} {...rest}>
      {children}
    </sup>
  );
}

Superscript1.propTypes = {
  'aria-level': PropTypes.string,
  className: PropTypes.string,
  'data-testid': PropTypes.string,
  id: PropTypes.string,
  itemProp: PropTypes.string,
  onClick: PropTypes.func,
  role: PropTypes.string,
  style: PropTypes.object,
};

export default Superscript1;
