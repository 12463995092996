import { transformPet } from './quote-utils';

export default function transformQuoteRedux(quote, { activeID, pets }) {
  const {
    email,
    last_name,
    is_registered,
    partner,
    policy_state,
    policy_zipcode,
    quote_pets = [],
    id,
    tracking_id,
    identity_id,
    transaction_fee,
    status,
  } = quote;
  let newActiveId = activeID; // id of the last pet mapped
  let { first_name } = quote;

  const transformedPets = quote_pets.map(quotePet => {
    const newPet = transformPet(quotePet);
    const oldPet = pets.find(statePet => quotePet.id === statePet.id) || {};
    newPet.plan = newPet.plan || oldPet.plan;

    newActiveId = activeID ?? newPet.id;

    return newPet;
  });

  if (first_name === 'Pet Parent') {
    first_name = '';
  }

  return {
    activeID: newActiveId,
    email,
    firstName: first_name,
    id,
    isRegistered: is_registered,
    lastName: last_name,
    partner,
    pets: transformedPets,
    policy_zipcode,
    policy_state,
    status,
    trackingId: tracking_id,
    identityId: identity_id,
    transactionFee: transaction_fee,
    vetAttribution: quote.vet || {},
  };
}
