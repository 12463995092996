import { useEffect } from 'react';

import {
  analyticsTrack,
  gaMarketingAttribution,
  getTrackedCustomProps,
} from '@pumpkincare/analytics';

import {
  getMarketingAttribution,
  setMarketingAttributionProperty,
} from './marketing-utils';

function useMarketingAttribution(quoteId, gaActionText, attributionKey) {
  const marketingAttribution = getMarketingAttribution();
  const isFirstVisit = !!marketingAttribution[attributionKey];

  useEffect(() => {
    function setToFalse() {
      if (isFirstVisit) {
        setMarketingAttributionProperty({ [attributionKey]: false });
      }
    }

    window.addEventListener('beforeunload', setToFalse);

    if (isFirstVisit) {
      analyticsTrack(
        gaMarketingAttribution({
          quoteId,
          event: gaActionText,
          source: marketingAttribution.source,
          content: marketingAttribution.content,
        }),

        getTrackedCustomProps()
      );
    }

    return () => setToFalse();
  }, [
    attributionKey,
    gaActionText,
    isFirstVisit,
    marketingAttribution.content,
    marketingAttribution.source,
    quoteId,
  ]);

  return isFirstVisit;
}

export default useMarketingAttribution;
