export function partition(filter, arr = []) {
  return arr.reduce(
    (result, elem) => {
      (filter(elem) ? result[0] : result[1]).push(elem);

      return result;
    },

    [[], []]
  );
}

export function removeDuplicates(arr = [], idField) {
  return [...new Map(arr.map(item => [item[idField], item])).values()];
}

export function sortByKey(list, key) {
  return list.sort((a, b) => {
    const valueA = a[key].toLowerCase();
    const valueB = b[key].toLowerCase();

    if (valueA < valueB) {
      return -1;
    } else if (valueA > valueB) {
      return 1;
    }

    return 0;
  });
}
