import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Body2, SocialMedia, Typography } from '../../ui';

import styles from './maintenance-frame.module.css';

function MaintenanceFrame() {
  const {
    state: { timeStart, timeEnd },
  } = useLocation();

  return (
    <div className={styles.container}>
      <Body2 className={styles.header}>
        <div className={styles.mainMenuLogoContainer}>
          <a href={'/'}>
            <img
              alt='Pumpkin Logo'
              src={`/assets/images/Pumpkin_Logo_Blue.svg`}
              className={styles.mainMenuLogo}
            />
          </a>
        </div>

        <SocialMedia
          containerProps={{ className: styles.socialIcons }}
          imageProps={{ className: styles.socialIcon }}
          type='rounded'
        />
      </Body2>

      <div className={styles.body}>
        <div className={styles.messageBox}>
          <div>
            <h2>Ruh Roh! </h2>
          </div>

          <div className={styles.messageContainer}>
            <span className={Typography.body2}>
              We’re currently performing a little maintenance, and some of our
              website might not be available. Please come back later today!
              <br />
            </span>
          </div>
        </div>

        <div className={styles.topIconContainer}>
          <img
            src='/assets/images/maintenance-icon.png'
            aria-hidden='true'
            alt='Seal'
            className={styles.topIcon}
          />
        </div>

        <div className={styles.leftIconContainer}>
          <img
            src='/assets/images/maintenance-dog.png'
            aria-hidden='true'
            alt='Seal'
            className={styles.leftIconImg}
          />
        </div>

        <div className={styles.rightIconContainer}>
          <img
            src='/assets/images/maintenance-cat.png'
            aria-hidden='true'
            alt='Seal'
            className={styles.rightIconImg}
          />
        </div>
      </div>
    </div>
  );
}

MaintenanceFrame.defaultProps = {
  timeStart: 'DATESTART',
  timeEnd: 'DATEEND',
};

MaintenanceFrame.propTypes = {
  timeStart: PropTypes.string,
  timeEnd: PropTypes.string,
};

export default MaintenanceFrame;
