export function formatFloat(n = 0, precision = 2) {
  if (isNaN(parseFloat(n))) {
    return 0;
  }

  return parseFloat(parseFloat(n).toFixed(precision));
}

/* @function formatLargeNum()
 *
 * @param  {number} num - Numeric value we want to format
 *
 * @returns {string} - Formatted numeric string, 5000 -> 5K
 * */
export function formatLargeNum(num) {
  if (num >= 1000000) return formatLargeNum(num / 1000000) + 'M';

  if (num >= 1000) return formatLargeNum(num / 1000) + 'K';

  return num.toString();
}
