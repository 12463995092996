import * as Sentry from '@sentry/browser';
import LogRocket from 'logrocket';

export const SANITIZED = '--sanitized--';

export function initLogRocket(appId) {
  LogRocket.init(appId, {
    dom: {
      inputSanitizer: true,
    },

    console: {
      isEnabled: {
        debug: true,
        error: true,
        log: true,
        warn: true,
      },

      shouldAggregateConsoleErrors: true,
    },

    network: {
      requestSanitizer,
    },

    // release: version,
    shouldCaptureIP: false,
  });

  LogRocket.getSessionURL(sessionURL => {
    Sentry.configureScope(scope => {
      scope.setExtra('sessionURL', sessionURL);
    });
  });
}

export function requestSanitizer(request) {
  const excludePatterns = ['aws', 'cognito', 'stripe', 'users/ach'];

  if (excludePatterns.some(pattern => request.url.includes(pattern))) {
    // ignore the request response pair
    return null;
  }

  if (request.headers['x-auth-token']) {
    request.headers['x-auth-token'] = SANITIZED;
  }

  if (request.headers['x-secret']) {
    request.headers['x-secret'] = SANITIZED;
  }

  return request;
}
