// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".circle_root_f2edebcf {\n  position: absolute;\n}\n\n.circle_bluesCluesBlue_a8b200a9 {\n  fill: var(--bluesCluesBlue);\n}\n\n.circle_cliffordRed_571a2718 {\n  fill: var(--cliffordRed);\n}\n\n.circle_plutoGold_c38535fa {\n  fill: var(--plutoGold);\n}\n\n.circle_scoutNavy_c88d7c38 {\n  fill: var(--scoutNavy);\n}", "",{"version":3,"sources":["webpack://../../libs/shared/src/ui/cosmetic/circle/circle.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;EACE,wBAAwB;AAC1B;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,sBAAsB;AACxB","sourcesContent":[".root {\n  position: absolute;\n}\n\n.bluesCluesBlue {\n  fill: var(--bluesCluesBlue);\n}\n\n.cliffordRed {\n  fill: var(--cliffordRed);\n}\n\n.plutoGold {\n  fill: var(--plutoGold);\n}\n\n.scoutNavy {\n  fill: var(--scoutNavy);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "circle_root_f2edebcf",
	"bluesCluesBlue": "circle_bluesCluesBlue_a8b200a9",
	"cliffordRed": "circle_cliffordRed_571a2718",
	"plutoGold": "circle_plutoGold_c38535fa",
	"scoutNavy": "circle_scoutNavy_c88d7c38"
};
export default ___CSS_LOADER_EXPORT___;
