import PropTypes from 'prop-types';

import { Body2 } from '@pumpkincare/shared/ui';

import VetClinic from '../vet-clinic';

import styles from './what-vet-clinic.module.css';

function WhatVetClinic({ pet, onChange, className }) {
  return (
    <div className={className}>
      <Body2 className={styles.required}>
        What vet clinic does {pet.name} go to?
      </Body2>

      <VetClinic className={styles.vetClinic} onChange={onChange} />
    </div>
  );
}

WhatVetClinic.defaultProps = {
  className: '',
  onChange: () => {},
};

WhatVetClinic.propTypes = {
  pet: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
  onChange: PropTypes.func,
  className: PropTypes.string,
};

export default WhatVetClinic;
