import { useCallback, useEffect, useRef, useState } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import Body2 from '../../typography/body-2';

import Typography from '../../typography.module.css';
import styles from './collapsible-panel.module.css';

import collapseDown from './collapse-down.svg';
import collapseUp from './collapse-up.svg';

function CollapsiblePanel({
  rootProps,
  children,
  title,
  defaultOpen,
  subTitle,
  titleIcon,
  classes,
}) {
  const [isOpen, setIsOpen] = useState(defaultOpen);
  const collapsibleEl = useRef(null);
  const defaultOpenRef = useRef(defaultOpen);

  const resizeCollapsible = useCallback(value => {
    if (collapsibleEl.current) {
      collapsibleEl.current.style.height =
        value ?? collapsibleEl.current.children[0].clientHeight + 'px';
    }
  }, []);

  useEffect(() => {
    function handleResize() {
      if (collapsibleEl.current?.clientHeight) {
        resizeCollapsible();
      }
    }

    if (defaultOpenRef.current) {
      resizeCollapsible();
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [resizeCollapsible]);

  function handlePanelOpening() {
    setIsOpen(prev => !prev);
    if (collapsibleEl.current) {
      resizeCollapsible(collapsibleEl.current.clientHeight ? 0 : null);
    }
  }

  const textAriaLabel = title ? ` for ${title}` : null;

  return (
    <div className={classes.collapseContainer} {...rootProps}>
      <button
        type='button'
        className={classnames(styles.collapseButton, classes.collapseButton)}
        onClick={handlePanelOpening}
      >
        <Body2 className={classnames(styles.subhead, classes.subhead)}>
          {titleIcon ? (
            <img src={titleIcon} aria-hidden='true' width='24' height='24' />
          ) : null}
          <span
            className={classnames(Typography.body1, styles.title, classes.title)}
          >
            {title}
          </span>
          {subTitle ? (
            <span
              className={classnames(
                Typography.body1,
                styles.subtitle,
                classes.subtitle
              )}
            >
              {subTitle}
            </span>
          ) : null}

          {isOpen ? (
            <img
              className={classnames(styles.collapseIcon, classes.collapseIcon)}
              src={collapseUp}
              aria-label={`collapse icon up${textAriaLabel}`}
            />
          ) : (
            <img
              className={classnames(styles.collapseIcon, classes.collapseIcon)}
              src={collapseDown}
              aria-label={`collapse icon down${textAriaLabel}`}
            />
          )}
        </Body2>
      </button>
      <div
        ref={collapsibleEl}
        className={classnames(styles.collapsible, classes.collapsible, {
          [styles.hidden]: !isOpen,
        })}
      >
        <div
          className={classnames(styles.contentContainer, classes.contentContainer, {
            [styles.hidden]: !isOpen,
          })}
        >
          {children}
        </div>
      </div>
    </div>
  );
}

CollapsiblePanel.defaultProps = {
  classes: {},
};

CollapsiblePanel.propTypes = {
  classes: PropTypes.shape({
    collapseContainer: PropTypes.string,
    collapseButton: PropTypes.string,
    collapseIcon: PropTypes.string,
    contentContainer: PropTypes.string,
    collapsible: PropTypes.string,
    title: PropTypes.string,
    subhead: PropTypes.string,
    subtitle: PropTypes.string,
  }),

  defaultOpen: PropTypes.bool,
  subTitle: PropTypes.node,
  title: PropTypes.node,
  titleIcon: PropTypes.string,
  rootProps: PropTypes.object,
};

export default CollapsiblePanel;
