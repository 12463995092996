import PropTypes from 'prop-types';

import GlobalBanner from '../global-banner';

import styles from './error-list-banner.module.css';

import alertTriangle from '../../images/alert-triangle.svg';

function ErrorListBanner({ errors, onClose, onError }) {
  return (
    <GlobalBanner
      banner={{
        type: 'error',
        title: 'Ruh-roh, looks like an error has been made.',
        icon: alertTriangle,
        message: (
          <div style={{ marginTop: '8px' }}>
            Please check on the following field(s):
            <ul className={styles.list}>
              {errors.map((error, i) => (
                <li key={i}>{error}</li>
              ))}
            </ul>
          </div>
        ),
      }}
      onClose={onClose}
      onError={onError}
    />
  );
}

ErrorListBanner.propTypes = {
  errors: PropTypes.arrayOf(PropTypes.string).isRequired,
  onClose: PropTypes.func.isRequired,
  onError: PropTypes.func,
};

export default ErrorListBanner;
