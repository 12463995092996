import {
  AW_CODE,
  ENVIRONMENT,
  GA_CODE,
  ONETRUST_ID,
  SEGMENT_KEY,
  SEGMENT_SRC_BASE,
  THREEBEARS_ID,
} from '@pumpkincare/config';

export function loadSegment(onReady, pageTitle) {
  const analytics = (window.analytics = window.analytics || []);

  if (!analytics.initialize) {
    if (analytics.invoked) {
      window.console &&
        console.error &&
        console.error('Segment snippet included twice.');
    } else {
      analytics.invoked = !0;
      analytics.methods = [
        'trackSubmit',
        'trackClick',
        'trackLink',
        'trackForm',
        'pageview',
        'identify',
        'reset',
        'group',
        'track',
        'ready',
        'alias',
        'debug',
        'page',
        'once',
        'off',
        'on',
      ];
      analytics.factory = function (t) {
        return function () {
          const e = Array.prototype.slice.call(arguments);
          e.unshift(t);

          analytics.push(e);

          return analytics;
        };
      };

      for (let t = 0; t < analytics.methods.length; t++) {
        const e = analytics.methods[t];

        analytics[e] = analytics.factory(e);
      }

      analytics.load = function (t, e) {
        const n = document.createElement('script');
        // Onetrust script will convert this to text/javascript based on block/allow for the user
        n.type = 'text/plain';
        n.className = 'optanon-category-C0003';
        n.async = !0;
        n.src = `${SEGMENT_SRC_BASE}/analytics.js/v1/${t}/analytics.min.js`;

        const a = document.getElementsByTagName('script')[0];
        a.parentNode.insertBefore(n, a);
        analytics._loadOptions = e;
      };
      analytics.SNIPPET_VERSION = '4.1.0';
      analytics.load(SEGMENT_KEY);

      const callback = function () {
        const segmentId = window.analytics.user().anonymousId();

        window.analytics.page(pageTitle);
        window.mixpanel.alias(segmentId);
        window.mixpanel.identify(segmentId);
        onReady();
      };

      window.analytics.ready(callback);
    }
  }
}

export function loadGoogleAnalytics() {
  const script = document.createElement('script');
  const jsScript = document.createElement('script');

  script.src = `https://www.googletagmanager.com/gtag/js?id=${GA_CODE}`;
  // Onetrust script will convert this to text/javascript based on block/allow for the user
  script.type = 'text/plain';
  script.className = 'optanon-category-C0001';

  jsScript.type = 'text/plain';
  jsScript.className = 'optanon-category-C0001';
  jsScript.innerHTML = `window.dataLayer = window.dataLayer || [];\n`;
  jsScript.innerHTML += `window.dataLayer.push({
    originalLocation: document.location.protocol + '//' +
                      document.location.hostname +
                      document.location.pathname +
                      document.location.search
});\n`;
  jsScript.innerHTML += 'function gtag(){dataLayer.push(arguments);}\n';
  jsScript.innerHTML += `gtag('js', new Date());\n`;
  jsScript.innerHTML += `window.analytics && window.analytics.ready(()=>{
    gtag('config', '${GA_CODE}', {
      'linker': {
        'domains': ['hello.pumpkin.care']
      }
    });
    window.dataLayer.push({ event: 'optimize.activate' });
  });\n`;
  jsScript.innerHTML += `gtag('config', '${AW_CODE}');`;

  document.body.appendChild(script);
  document.body.appendChild(jsScript);
}

export function loadOneTrust() {
  const script = document.createElement('script');
  const wrapScript = document.createElement('script');
  script.src = 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js';
  script.type = 'text/javascript';
  script.dataset.domainScript = ONETRUST_ID;
  wrapScript.innerHTML += 'function OptanonWrapper() { };\n';
  document.head.prepend(wrapScript);
  document.head.prepend(script);
}

export function load3Bears() {
  if (ENVIRONMENT === 'production') {
    const script = document.createElement('script');
    script.type = 'text/plain';
    script.className = 'optanon-category-C0002';
    script.innerHTML =
      '(function(b,e,a,r,s) {\n' +
      '    b=b[s]=b[s]||{q:[],onReady:function(c){b.q.push(c)}}\n' +
      '    s=e.createElement(a);s.async=1;s.src=r\n' +
      '    r=e.getElementsByTagName(a)[0];r.parentNode.insertBefore(s,r) })' +
      '    (window,document,"script","https://cdn.3bears.ai/static/js/3bears.js","TB_LOGS")\n' +
      '    TB_LOGS.onReady(function(){\n' +
      '    TB_LOGS.init({\n' +
      `       clientToken: "${THREEBEARS_ID}",\n` +
      '   })\n' +
      ' })\n';
    document.head.appendChild(script);
  }
}
