import { useQuery } from 'react-query';

import {
  getQuoteId,
  getQuotePet,
  getQuotePolicyZipCode,
  useQuote,
} from '@pumpkincare/quotes';
import { getIsLoggedIn } from '@pumpkincare/shared';

import { getPolicyOptions } from './plans-service';

export const POLICY_OPTIONS_QUERY = 'policyOptions';

export function usePolicyOptions(petId) {
  const isLoggedIn = getIsLoggedIn();

  const { data: quoteData } = useQuote();
  const quoteId = getQuoteId(quoteData);
  const policyZipCode = getQuotePolicyZipCode(quoteData);
  const petData = getQuotePet(quoteData)(petId);

  return useQuery(
    [POLICY_OPTIONS_QUERY, quoteId, petId],

    () => getPolicyOptions(quoteId, petId, isLoggedIn),

    {
      // we need to know quote policy / zip and pet to be complete before getting policy options
      enabled:
        !!quoteId &&
        !!petId &&
        !!policyZipCode &&
        !!petData.petAge &&
        !!petData.petBreedCode,
      staleTime: Infinity,
    }
  );
}
