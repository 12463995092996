// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[2].use[1]!../../breakpoints.module.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".simple-header_root_2e8b0ea7 {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n\n    padding-left: var(--column1);\n    padding-right: var(--column2);\n}\n\n.simple-header_link_37a2e569 {\n    display: flex;\n    align-items: center;\n    width: var(--column1);\n}\n\n.simple-header_heading_d0efcb06 {\n    text-align: center;\n    position: relative;\n    flex: 1;\n}\n\n.simple-header_hidden_49e8938f {}\n\n@media " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["downXs"] + " {\n    .simple-header_root_2e8b0ea7 {\n        padding-left: 0;\n    }\n\n    .simple-header_link_37a2e569 {\n        width: var(--column2);\n    }\n\n    .simple-header_hidden_49e8938f {\n        display: none;\n    }\n}", "",{"version":3,"sources":["webpack://../../libs/shared/src/ui/navigation/simple-header/simple-header.module.css"],"names":[],"mappings":"AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,mBAAmB;;IAEnB,4BAA4B;IAC5B,6BAA6B;AACjC;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,qBAAqB;AACzB;;AAEA;IACI,kBAAkB;IAClB,kBAAkB;IAClB,OAAO;AACX;;AAEA,gCAAS;;AAET;IACI;QACI,eAAe;IACnB;;IAEA;QACI,qBAAqB;IACzB;;IAEA;QACI,aAAa;IACjB;AACJ","sourcesContent":["@value downXs from '../../breakpoints.module.css';\n\n.root {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n\n    padding-left: var(--column1);\n    padding-right: var(--column2);\n}\n\n.link {\n    display: flex;\n    align-items: center;\n    width: var(--column1);\n}\n\n.heading {\n    text-align: center;\n    position: relative;\n    flex: 1;\n}\n\n.hidden {}\n\n@media downXs {\n    .root {\n        padding-left: 0;\n    }\n\n    .link {\n        width: var(--column2);\n    }\n\n    .hidden {\n        display: none;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"downXs": "" + ___CSS_LOADER_ICSS_IMPORT_0___.locals["downXs"] + "",
	"root": "simple-header_root_2e8b0ea7",
	"link": "simple-header_link_37a2e569",
	"heading": "simple-header_heading_d0efcb06",
	"hidden": "simple-header_hidden_49e8938f"
};
export default ___CSS_LOADER_EXPORT___;
