import _ from 'lodash';

import { CAT, DOG } from '@pumpkincare/shared';

import {
  CAT_MEDICATIONS,
  DOG_MEDICATIONS,
  DOG_MEDICATIONS_WITH_PROHEART,
  NONE_MEDICATION,
  SIMPARICA_PROHEART_OPTION,
  SIMPARICA_PROHEART_PROTECTION_MEDS,
  SIMPARICA_TRIO_OPTION,
  SIMPARICA_TRIO_PROTECTION_MEDS,
} from '../constants/pet-medications';

export function createPetVetIds(petIds, vets) {
  const ids = {};

  petIds.forEach((petId, index) => {
    ids[petId] = vets[index].id;
  });

  return ids;
}

export function addVetIdsToAnswers(answers, petVetIds) {
  const newAnswers = _.cloneDeep(answers);

  newAnswers.forEach(answer => {
    if (answer.vet_pending && answer.vet_pending in petVetIds) {
      answer.vet_id = petVetIds[answer.vet_pending];
    }

    delete answer.vet_pending;
  });

  return newAnswers;
}

export function createVetFormPayload(petsAnswers) {
  const customVets = [];
  const answers = [];

  function checkMedsFromProtectionOption(medications, medsToCheck, medsToUncheck) {
    medications.forEach(med => {
      if (medsToCheck.includes(med.id)) {
        med.checked = true;
      }

      if (medsToUncheck.includes(med.id)) {
        med.checked = false;
      }
    });
  }

  function addMedsFromProtectionOption(medications, protectionAnswer) {
    if (protectionAnswer === SIMPARICA_PROHEART_OPTION) {
      checkMedsFromProtectionOption(
        medications,
        SIMPARICA_PROHEART_PROTECTION_MEDS,
        SIMPARICA_TRIO_PROTECTION_MEDS
      );
    } else if (protectionAnswer === SIMPARICA_TRIO_OPTION) {
      checkMedsFromProtectionOption(
        medications,
        SIMPARICA_TRIO_PROTECTION_MEDS,
        SIMPARICA_PROHEART_PROTECTION_MEDS
      );
    }
    return medications;
  }

  function transformMedications(medications, protectionAnswer) {
    const selectedMeds = addMedsFromProtectionOption(medications, protectionAnswer);

    return selectedMeds
      .filter(medication => medication.checked)
      .map(medication => medication.id);
  }

  function createBasicPayload(pet) {
    const answer = {
      pet_id: pet.id,
      medications: transformMedications(pet.medications || [], pet.protectionAnswer),
    };

    if (pet.weight) {
      answer.weight = pet.weight;
    }

    if (pet.birthDate) {
      answer.birth_date = pet.birthDate;
    }

    return answer;
  }

  petsAnswers.forEach(pet => {
    let answer = createBasicPayload(pet);

    // selected from the list
    if (pet.vet && pet.vet.id) {
      answer.vet_id = pet.vet.id;
      answer.vet_pending = null;
    }
    // custom vet
    else if (pet.vet && pet.vet.custom) {
      customVets.push({
        pet_id: pet.id,
        vet: pet.vet.custom,
      });

      answer.vet_id = null;
      answer.vet_pending = pet.id;
    }
    // same vet of another pet
    else {
      const otherPetId = pet.sameVetOfPet.replace('yes|', '');
      const otherPet = answers.find(answer => answer.pet_id === otherPetId);

      // if the other pet has vet.id, repeat
      if (otherPet && otherPet.vet_id) {
        answer.vet_id = otherPet.vet_id;
        answer.vet_pending = null;
      }
      // the other pet has a custom vet, so it's pending too
      else {
        answer.vet_id = null;
        answer.vet_pending = otherPetId;
      }
    }

    answers.push(answer);
  });

  return { customVets, answers };
}

export function hasVet(answer) {
  return !!(answer.vet && answer.vet.id);
}

export function obtainMedicationsList(
  vet,
  pet,
  checkedValues,
  isPetPreventiveCare2
) {
  const isVetOfferProheart = vet ? vet.has_proheart : false;

  return createMedicationsValues(
    pet.species,
    checkedValues,
    isPetPreventiveCare2 && isVetOfferProheart
  ).concat([
    {
      id: NONE_MEDICATION,
      label: `${pet.name} doesn't take any of these`,
      checked: false,
    },
  ]);
}

function createMedicationsValues(species, checkedValues, isOfferProheart) {
  if (![DOG, CAT].includes(species)) {
    return [];
  }

  if (!Array.isArray(checkedValues)) {
    checkedValues = [];
  }

  const medications =
    species === DOG
      ? isOfferProheart
        ? DOG_MEDICATIONS_WITH_PROHEART
        : DOG_MEDICATIONS
      : CAT_MEDICATIONS;
  return medications.map(med => ({
    id: med,
    label: med,
    checked: checkedValues.includes(med),
  }));
}
