// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[2].use[1]!../../breakpoints.module.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".show-once-banner_bannerRoot_07d9ae47 {\n    align-items: center;\n    height: 56px;\n    padding: 16px 40px;\n}\n\n.show-once-banner_closeButton_eb0374fc {\n    top: 11px;\n}\n\n.show-once-banner_bannerIcon_0626e91d {\n    width: 24px;\n    height: 24px;\n}\n\n@media " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["b-mobile"] + " {\n    .show-once-banner_bannerRoot_07d9ae47 {\n        height: auto;\n        padding: 16px 64px 16px 32px;\n    }\n}", "",{"version":3,"sources":["webpack://../../libs/shared/src/ui/notifications/show-once-banner/show-once-banner.module.css"],"names":[],"mappings":"AAEA;IACI,mBAAmB;IACnB,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,SAAS;AACb;;AAEA;IACI,WAAW;IACX,YAAY;AAChB;;AAEA;IACI;QACI,YAAY;QACZ,4BAA4B;IAChC;AACJ","sourcesContent":["@value b-mobile from '../../breakpoints.module.css';\n\n.bannerRoot {\n    align-items: center;\n    height: 56px;\n    padding: 16px 40px;\n}\n\n.closeButton {\n    top: 11px;\n}\n\n.bannerIcon {\n    width: 24px;\n    height: 24px;\n}\n\n@media b-mobile {\n    .bannerRoot {\n        height: auto;\n        padding: 16px 64px 16px 32px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"b-mobile": "" + ___CSS_LOADER_ICSS_IMPORT_0___.locals["b-mobile"] + "",
	"bannerRoot": "show-once-banner_bannerRoot_07d9ae47",
	"closeButton": "show-once-banner_closeButton_eb0374fc",
	"bannerIcon": "show-once-banner_bannerIcon_0626e91d"
};
export default ___CSS_LOADER_EXPORT___;
