import classNames from 'classnames';
import PropTypes from 'prop-types';

import styles from './arrow-next-button.module.css';

import blueArrow from './right-arrow-blue.svg';
import whiteArrow from './right-arrow-white.svg';

function ArrowNextButton({
  classes,
  imgAlt,
  onClick,
  small,
  disabled,
  inverted,
  reverse,
}) {
  const buttonClassName = classNames(styles.root, classes.root, {
    [styles.inverted]: inverted,
    [styles.disabled]: disabled,
    [styles.small]: small,
  });
  const imageClassName = classNames(styles.image, classes.image, {
    [styles.reverse]: reverse,
  });

  return (
    <button
      type='button'
      className={buttonClassName}
      onClick={onClick}
      disabled={disabled}
    >
      <img
        className={imageClassName}
        src={inverted ? whiteArrow : blueArrow}
        alt={imgAlt ? imgAlt : reverse ? 'Previous' : 'Next'}
      />
    </button>
  );
}

ArrowNextButton.defaultProps = {
  classes: {},
  inverted: false,
  disabled: false,
  reverse: false,
  small: false,
};

ArrowNextButton.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string,
    image: PropTypes.string,
  }),

  onClick: PropTypes.func.isRequired,
  /** For accessibility, you can overwrite the default imgAlt, which is either Next or Previous (if reverse) */
  imgAlt: PropTypes.string,
  inverted: PropTypes.bool,
  disabled: PropTypes.bool,
  reverse: PropTypes.bool,
  small: PropTypes.bool,
};

export default ArrowNextButton;
