// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".confirmation-panel_controls_8b9dd7b2 {\n  display: flex;\n  justify-content: space-between;\n  flex-direction: row;\n\n  width: 240px;\n  margin-top:16px;\n}\n\n.confirmation-panel_controls_8b9dd7b2 .confirmation-panel_confirmButton_f9b7bc0c {\n  width: 100px;\n  height: 40px;\n  margin: 0;\n}\n\n.confirmation-panel_errorCaption_c86c0e3b:not(:empty) {\n  color: var(--cliffordRed);\n  padding-top: 10px;\n}\n\n.confirmation-panel_confirmCaption_0a3a5a91 {\n  color: var(--dinoBlue);\n  font-style: normal;\n}", "",{"version":3,"sources":["webpack://../../libs/shared/src/ui/buttons/confirmation-panel/confirmation-panel.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;;EAEnB,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,YAAY;EACZ,YAAY;EACZ,SAAS;AACX;;AAEA;EACE,yBAAyB;EACzB,iBAAiB;AACnB;;AAEA;EACE,sBAAsB;EACtB,kBAAkB;AACpB","sourcesContent":[".controls {\n  display: flex;\n  justify-content: space-between;\n  flex-direction: row;\n\n  width: 240px;\n  margin-top:16px;\n}\n\n.controls .confirmButton {\n  width: 100px;\n  height: 40px;\n  margin: 0;\n}\n\n.errorCaption:not(:empty) {\n  color: var(--cliffordRed);\n  padding-top: 10px;\n}\n\n.confirmCaption {\n  color: var(--dinoBlue);\n  font-style: normal;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"controls": "confirmation-panel_controls_8b9dd7b2",
	"confirmButton": "confirmation-panel_confirmButton_f9b7bc0c",
	"errorCaption": "confirmation-panel_errorCaption_c86c0e3b",
	"confirmCaption": "confirmation-panel_confirmCaption_0a3a5a91"
};
export default ___CSS_LOADER_EXPORT___;
