import { useQuery } from 'react-query';

import { checkProductsByState } from '@pumpkincare/shared';

export const PRODUCTS_BY_STATE_QUERY = 'productsByState';

export const getProductHasInsurance = products => products.insurance;
export const getProductHasPrevent = products => products.prevent;
export const getProductCanUpsellPrevent = products => products.can_upsell_prevent;

export function useProductsByState(policyState) {
  return useQuery(
    [PRODUCTS_BY_STATE_QUERY, policyState],

    () => checkProductsByState(policyState),

    {
      enabled: !!policyState,

      placeholderData: {
        insurance: false,
        prevent: false,
        can_upsell_prevent: false,
      },
    }
  );
}
