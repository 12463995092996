const NB_DASH = `\u2011`; //nonbreaking dash
const PHONE_NUMBER = `18662736369`;

export const PHONE_NUMBER_LINK = `tel:+${PHONE_NUMBER}`;
export const FORMATTED_PHONE_NUMBER = `1${NB_DASH}866${NB_DASH}273${NB_DASH}6369`;
export const ARF_MEOW_PHONE_NUMBER = `1${NB_DASH}866${NB_DASH}ARF${NB_DASH}MEOW`;
export const PHONE_ARIA_LABEL = `Give us a call at ${ARF_MEOW_PHONE_NUMBER} also known as ${FORMATTED_PHONE_NUMBER}. Selecting this will launch your default call settings.`;
export const SUPPORT_HOURS = `Mon-Fri 8am-8pm EST`;
export const CONTACT_EMAIL = `help@pumpkin.care`;
export const CONTACT_EMAIL_LINK = `mailto:${CONTACT_EMAIL}`;
export const HELLO_EMAIL = `hello@pumpkin.care`;
export const HELLO_EMAIL_LINK = `mailto:${HELLO_EMAIL}`;
