import { Link } from 'react-router-dom';

import { captureException } from '@pumpkincare/shared';

import { analyticsTrack } from '../analytic-utils';

export default function GaLink({ children, onClick, ...rest }) {
  function clickHandler(e) {
    // ensure that errors in tracking do not impact site functionality
    try {
      const { label, event, category, value } = e.target.dataset;
      const analyticsEvent = {
        category,
        event,
        label,
        value,
      };

      if (category && event) {
        analyticsTrack(analyticsEvent);
      }
    } catch (err) {
      // make sure we'll learn about this error anyways
      captureException(err);
    }

    if (onClick) {
      onClick(e);
    }
  }

  return rest.href ? (
    <a onClick={clickHandler} {...rest}>
      {children}
    </a>
  ) : (
    <Link onClick={clickHandler} {...rest}>
      {children}
    </Link>
  );
}
