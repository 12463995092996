import axios from 'axios';

import { POLICY_BASE_API } from '@pumpkincare/config';

export function validateDiscount(passcode) {
  const url = `${POLICY_BASE_API}/discounts`;

  return axios.post(url, { passcode }).then(response => response.data.body);
}

export function validateDiscountByZipCode(discountId, zipCode) {
  const url = `${POLICY_BASE_API}/discounts/${discountId}/zipcode/${zipCode}`;

  return axios.get(url).then(response => response.data.body);
}
