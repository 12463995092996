export function getQueryParam(variable, search = window.location.search) {
  const query = search.substring(1);
  const params = query.split('&');

  for (let i = 0; i < params.length; i++) {
    const pair = params[i].split('=');

    if (pair[0] === variable) {
      return pair[1] || true;
    }
  }

  return false;
}

export function convertIfBool(value) {
  return value === 'true' || (value === 'false' ? false : value);
}
