import { ANNUAL_LIMIT, COPAY, DEDUCTIBLE, LEVER_NAMES } from './constants';
import { formatLevers, formatLeversTitle } from './plan-utils';

export default function getPetPlanData(
  pet,
  plan,
  policyOptions,
  recommendationOptions
) {
  const { petBreedSpecies, petName } = pet;
  const species = petBreedSpecies.toLowerCase();

  const unformattedRecommendations = recommendationOptions.recommendations;
  const recommendations = Object.keys(unformattedRecommendations).reduce(
    (result, key) => {
      const { id } = unformattedRecommendations[key];
      result[key] = { id, value: id.replace(/\D/g, '') };
      return result;
    },
    {}
  );

  const activeValues = {
    deductible: pet.deductible || recommendations.deductible.value,
    copay: pet.copay || recommendations.copay.value,
    annual_limit: pet.annual_limit || recommendations.annual_limit.value,
  };

  function getPrice(prices, deductible = activeValues.deductible) {
    return Number(
      prices[`Copay${activeValues.copay}`][`Limit${activeValues.annual_limit}`][
        `Deductible${deductible}`
      ]
    ).toFixed(2);
  }

  const { levers, prices } = formatLevers(policyOptions, getPrice);
  const { onlyOptionSubtitles, availableLevers } = LEVER_NAMES.reduce(
    (result, item) => {
      if (levers[item].length === 1) {
        result.onlyOptionSubtitles.push(`${levers[item][0].title} fur all`);
      } else {
        result.availableLevers[item] = levers[item];
      }
      return result;
    },
    { onlyOptionSubtitles: [], availableLevers: {} }
  );
  const leversTitle = formatLeversTitle(availableLevers);
  const activePrice = getPrice(prices);

  const submitButton = {
    selectedPolicyPrice: activePrice,
    preventPrice: plan.cost,
    totalPrice:
      plan && plan.cost && activePrice
        ? (parseFloat(activePrice) + parseFloat(plan.cost)).toFixed(2)
        : null,
  };

  return {
    activeValues,
    infoContainer: {
      whyDoWeRecommend: {
        lineItems: [
          recommendationOptions.why_do_we_recommend[COPAY],
          recommendationOptions.why_do_we_recommend[ANNUAL_LIMIT],
          recommendationOptions.why_do_we_recommend[DEDUCTIBLE],
        ].filter(item => item),
      },
    },
    insurancePicker: {
      prices,
      leversTitle,
      availableLevers,
      onlyOptionSubtitles,
      recommendations,
      activePrice,
    },
    coverageDetails: {
      header: `${petName}'s Recommended Plan`,
      detailsTitle: `Pumpkin insurance plans cover many common ${species} accidents and illnesses.`,
      recommendedPlan: recommendationOptions.recommended_plan,
      recommendedPlanOrder: [COPAY, ANNUAL_LIMIT, DEDUCTIBLE],
    },
    submitButton,
  };
}
