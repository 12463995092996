import { Auth } from 'aws-amplify';
import axios from 'axios';

import { POLICY_BASE_API } from '@pumpkincare/config';

export function searchVetClinic(term) {
  const url = `${POLICY_BASE_API}/vet-practices/suggestions`;

  return Auth.currentAuthenticatedUser().then(user => {
    return axios
      .post(
        url,
        { vet_name: term },
        {
          headers: {
            Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}`,
          },
        }
      )
      .then(response => response.data.body);
  });
}

export function postVetClinicSearchWithZip(term, zipcode) {
  const url = `${POLICY_BASE_API}/vet-practices/zipcode-suggestions`;

  return axios
    .post(url, { vet_name: term, zipcode })
    .then(response => response.data.body);
}

export function postCustomVets(vets) {
  const url = `${POLICY_BASE_API}/vet-practices`;

  return Auth.currentAuthenticatedUser().then(user => {
    return axios
      .post(url, vets, {
        headers: {
          Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}`,
        },
      })
      .then(response => response.data.body);
  });
}

export function submitAnswers(userId, answers) {
  const url = `${POLICY_BASE_API}/users/${userId}/vet-form`;

  return Auth.currentAuthenticatedUser().then(cognitoUser => {
    return axios.post(url, answers, {
      headers: {
        Authorization: `Bearer ${cognitoUser.signInUserSession.idToken.jwtToken}`,
      },
    });
  });
}

export function getAnswers(userId) {
  const url = `${POLICY_BASE_API}/users/${userId}/vet-form`;

  return Auth.currentAuthenticatedUser().then(cognitoUser => {
    return axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${cognitoUser.signInUserSession.idToken.jwtToken}`,
        },
      })
      .then(response => response.data.body);
  });
}

export const VetsService = {
  searchVetClinic,
  postVetClinicSearchWithZip,
  submitCustomVets: postCustomVets,
  submitAnswers,
  getAnswers,
};
