import { useEffect } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Spinner from '../../informational/spinner';

import Typography from '../../typography.module.css';
import styles from './loading-steps.module.css';

import checkIcon from './checked-list-checked.svg';

function LoadingSteps({ steps, onFinishAnimation }) {
  useEffect(() => {
    const timer = setTimeout(onFinishAnimation, steps.length * 1000);

    return () => {
      clearTimeout(timer);
    };
  }, [steps, onFinishAnimation]);

  return steps.map((step, index) => (
    <div className={styles.stepBox} key={String(index)}>
      <div className={styles.iconBox}>
        <div
          style={{
            animationDelay: `${index + 1}s`,
          }}
          className={styles.hideAfterDelay}
        >
          <Spinner size={20} />
        </div>
        <div
          style={{
            animationDelay: `${index + 2}s`,
          }}
          className={styles.showAfterDelay}
        >
          <img src={checkIcon} className={styles.checkIcon} role='presentation' />
        </div>
      </div>

      <span
        style={{
          animationDelay: `${index + 2}s`,
        }}
        className={classNames(Typography.body2, styles.loadingText)}
        aria-label='step-text'
      >
        {step}
      </span>
    </div>
  ));
}

LoadingSteps.propTypes = {
  steps: PropTypes.array.isRequired,
  onFinishAnimation: PropTypes.func.isRequired,
};

export default LoadingSteps;
