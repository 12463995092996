import axios from 'axios';

import { PERSONALIZATION_BASE_API } from '@pumpkincare/config';

export function postIdentity() {
  const url = `${PERSONALIZATION_BASE_API}/identities`;

  return axios.post(url).then(response => response.data);
}

export function putIdentity(trackingId) {
  const url = `${PERSONALIZATION_BASE_API}/identities/tracking-id/${trackingId}`;

  return axios.put(url).then(response => response.data);
}

export function patchIdentity(payload) {
  const url = `${PERSONALIZATION_BASE_API}/identities/${payload.id}`;

  return axios.patch(url, payload).then(response => response.data);
}

export function postIdentityHdyhau(id, payload) {
  const url = `${PERSONALIZATION_BASE_API}/identities/${id}/hdyhau`;

  return axios.post(url, payload).then(response => response.data);
}

export function getIdentityQuestionSet(id) {
  const url = `${PERSONALIZATION_BASE_API}/identities/${id}/question-set`;

  return axios.get(url).then(response => response.data);
}

export function postIdentitiesNextQuestionVariant(identityId, petBreedSpecies) {
  const url = `${PERSONALIZATION_BASE_API}/identities/${identityId}/next-question-variant`;

  return axios.post(url, {
    species: petBreedSpecies.toLowerCase(),
  });
}

export function postAnswers(identityId, questionVariantId, answers = []) {
  const url = `${PERSONALIZATION_BASE_API}/identities/${identityId}/question-variant/${questionVariantId}/answers`;

  return axios.post(
    url,
    answers.map(answer => ({
      question_variant_answer_id: answer.id,
    }))
  );
}

export const IdentityService = {
  postIdentity,
  putIdentity,
  patchIdentity,
  postIdentityHdyhau,
  getIdentityQuestionSet,
};
