// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".error-boundary_errorBoundary_642fe37e {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    text-align: center;\n\n    width: auto;\n    margin: 40px auto;\n    max-width: 520px;\n}\n\n.error-boundary_h4_b0be5fee {\n    margin: 24px 0 16px;\n}\n\n.error-boundary_blue_0ee444a0 {\n    color: var(--bluesCluesBlue);\n}\n\n.error-boundary_button_e1fc2d7a {\n    width: 100%;\n    max-width: 285px;\n    height: 48px;\n\n    margin: 48px 0 0;\n}", "",{"version":3,"sources":["webpack://../../libs/shared/src/ui/notifications/error-boundary/error-boundary.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,kBAAkB;;IAElB,WAAW;IACX,iBAAiB;IACjB,gBAAgB;AACpB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,4BAA4B;AAChC;;AAEA;IACI,WAAW;IACX,gBAAgB;IAChB,YAAY;;IAEZ,gBAAgB;AACpB","sourcesContent":[".errorBoundary {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    text-align: center;\n\n    width: auto;\n    margin: 40px auto;\n    max-width: 520px;\n}\n\n.h4 {\n    margin: 24px 0 16px;\n}\n\n.blue {\n    color: var(--bluesCluesBlue);\n}\n\n.button {\n    width: 100%;\n    max-width: 285px;\n    height: 48px;\n\n    margin: 48px 0 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"errorBoundary": "error-boundary_errorBoundary_642fe37e",
	"h4": "error-boundary_h4_b0be5fee",
	"blue": "error-boundary_blue_0ee444a0",
	"button": "error-boundary_button_e1fc2d7a"
};
export default ___CSS_LOADER_EXPORT___;
