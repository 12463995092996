// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "body {\n  margin: 0px;\n}\n\nhtml {\n  scroll-behavior: smooth;\n}\n\n.ui_inject_c891b7f1 {\n  display: none;\n}", "",{"version":3,"sources":["webpack://../../libs/shared/src/ui/global.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;AACb;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,aAAa;AACf","sourcesContent":["body {\n  margin: 0px;\n}\n\nhtml {\n  scroll-behavior: smooth;\n}\n\n.inject {\n  display: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inject": "ui_inject_c891b7f1"
};
export default ___CSS_LOADER_EXPORT___;
