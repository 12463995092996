import PropTypes from 'prop-types';

import TextField from '../text-field';

function ZipcodeField(props) {
  function handleZipcodeInputPaste(e) {
    e.preventDefault();

    const pasteText = e.clipboardData.getData('text/plain').replace(/[^0-9]/g, '');
    e.target.value = (e.target.value + pasteText).substring(0, 5);

    props.onChange(e);
    e.target.dispatchEvent(new Event('change'));
  }

  return (
    <TextField
      maxLength={5}
      onPaste={handleZipcodeInputPaste}
      pattern='[0-9]{9}'
      {...props}
    />
  );
}

ZipcodeField.propTypes = {
  // TextField PropTypes
  classes: PropTypes.shape({
    label: PropTypes.string,
    input: PropTypes.string,
    container: PropTypes.string,
    fieldContainer: PropTypes.string,
  }),
  label: PropTypes.string,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  isLabelHidden: PropTypes.bool,
  variant: PropTypes.oneOf(['default', 'outline', 'bordered']),
  propRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  inputRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  error: PropTypes.shape({
    errorMessage: PropTypes.node,
    hasError: PropTypes.bool,
    isErrorHidden: PropTypes.bool,
  }),
  endAdornment: PropTypes.shape({
    icon: PropTypes.node,
    isLoading: PropTypes.bool,
    endContent: PropTypes.node,
    endCustom: PropTypes.node,
    onIconClick: PropTypes.func,
  }),
  startAdornment: PropTypes.shape({
    startContent: PropTypes.node,
    startCustom: PropTypes.node,
  }),

  // spread via ...rest
  autoComplete: PropTypes.oneOf(['off', 'on']),
  autoFocus: PropTypes.bool,
  maxLength: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  onKeyUp: PropTypes.func,
  type: PropTypes.string,
  name: PropTypes.string,
  'data-testid': PropTypes.string,
  id: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  defaultValue: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  readOnly: PropTypes.bool,
};

export default ZipcodeField;
